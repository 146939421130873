import { Address } from "./../new-model/address";
import { Serializable } from "../new-model/serializable";
import { ContactPerson } from "../new-model/contact-person";

export class Store implements Serializable<Store> {
  email: string;

  epayNumber: string;

  guid: string;

  name: string;

  phone: string;

  storeNumber: string;

  deserialize(input: any) {
    this.email = input.email || "";
    this.epayNumber = input.epay_num || "";
    this.guid = input.guid || "";
    this.name = input.name || "";
    this.phone = input.phone || "";
    this.storeNumber = input.store_num || "";
    return this;
  }

  serialize() {
    return {
      email: this.email,
      epay_num: this.epayNumber,
      guid: this.guid,
      name: this.name,
      phone: this.phone,
      store_num: this.storeNumber
    };
  }
}

export class StoreDetails extends Store {
  address: Address;
  contact: ContactPerson;
  appleStoreID: string;
  googleStoreID: string;
  googleRegion: string;
  googleStoreType: string;
  regionInt: string;
  lekkerlandStoreCategory: string;

  constructor() {
    super();
  }

  deserialize(input: any) {
    super.deserialize(input);
    this.address = new Address().deserialize(input.address || {});
    this.contact = new ContactPerson().deserialize(input.contact || {});
    this.appleStoreID = input.apple_store_id || '';
    this.googleStoreID = input.google_store_id || '';
    this.googleRegion = input.google_region || '';
    this.googleStoreType = input.google_store_type || '';
    this.lekkerlandStoreCategory = input.lekkerland_store_category || '';
    this.regionInt = input.region || '';
    return this;
  }

  serialize() {
    return Object.assign(
      super.serialize(),
      { address: this.address.serialize() },
      { contact: this.contact.serialize() },
      {
        apple_store_id: this.appleStoreID,
        google_store_id: this.googleStoreID,
        google_store_type: this.googleStoreType,
        googleRegion: this.googleRegion,
        region: this.regionInt,
        lekkerland_store_category:this.lekkerlandStoreCategory
      }
    );
  }
}


export class StoreFixture implements Serializable<StoreFixture> {

  projectName: string;
  lastVisitDate: string;
  fixtures: StoreFixtureDetails[];
  equipmentNo: string;

  constructor() {
    this.projectName = "";
    this.lastVisitDate = "";
    this.fixtures = [];
    this.equipmentNo = "";
  }

  deserialize(input: any) {
    this.projectName = input.project_name || "";
    this.lastVisitDate = input.last_visit_date && input.last_visit_date.date ? new Date(input.last_visit_date.date).toLocaleDateString('de') : "";
    this.fixtures = input.fixtures && input.fixtures.map(fixture => new StoreFixtureDetails().deserialize(fixture)) || [];
    this.equipmentNo = input.equipment_no || "";
    return this;
  }

  serialize() {
    return {
      project_name: this.projectName,
      last_visit_date: this.lastVisitDate,
      fixtures: this.fixtures,
      equipment_no: this.equipmentNo,
    };
  }
}


export class StoreFixtureDetails {
  fixtureName: string;
  fixtureSerialNumber: string;
  fixtureType: string;

  deserialize(input: any) {
    this.fixtureName = input.fixture_name || "";
    this.fixtureSerialNumber = input.fixture_serial_number || "";
    this.fixtureType = input.fixtureType || "";
    return this;
  }

  serialize() {
    return {
      fixture_name: this.fixtureName,
      fixture_serial_number: this.fixtureSerialNumber,
      fixtureType: this.fixtureType,
    };
  }
}
export const contactDetailOptions = [
    {
        type: 1,
        value: "Mail address"
    },
    {
        type: 2,
        value: "Mobile phone"
    },
    {
        type: 3,
        value: "Work phone"
    }

]
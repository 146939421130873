import { Retailer } from './../new-model/retailer';
import { Serializable } from './../new-model/serializable';
import { SurveyQuestion } from './surveyQuestion';
import { Fixture } from '../new-model/fixture';

export class Survey implements Serializable<Survey> {
    id: number;
    active: boolean;
    guid: string;
    name: string;
    retailer: Retailer[];
    fixture: Fixture[];
    questions: SurveyQuestion[];
    hasException: {};


    deserialize(element: any) {
        this.id = element.id || '';
        this.active = element.active || false;
        this.guid = element.guid || '';
        this.name = element.name || '';
        this.retailer = (element.retailer instanceof Array) ? element.retailer.map((r: Retailer) => new Retailer().deserialize(r)) : [];
        this.fixture = (element.fixture instanceof Array) ? element.fixture.map((fx: Fixture) => new Fixture().deserialize(fx)) : [];
        this.questions = (element.survey_questions instanceof Array) ? element.survey_questions.map((question: SurveyQuestion) => new SurveyQuestion().deserialize(question)) : [];
        this.hasException = element.has_exception;
        return this;
    }

    serialize() {
        return {
            id: this.id,
            guid: this.guid,
            name: this.name,
            active: this.active,
            retailer: this.retailer.map((r: Retailer) => new Retailer().serialize()),
            fixture: this.fixture.map((fx: Fixture) => new Fixture().serialize()),
            survey_questions: this.questions.map(question => new SurveyQuestion().serialize()),
            has_exception: this.hasException
        }
    }
}

import { TranslateService } from './services/translate.service';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule, MatDividerModule, MatGridListModule, DateAdapter, MatExpansionModule } from '@angular/material';
import { BrowserModule } from "@angular/platform-browser";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ScrollDirective } from "./directives/scrollDirective";
import { BasicProjDataFormComponent } from "./plentycore/steps/step1/forms/basic-proj-data-form/basic-proj-data-form.component";
import { BasicRetailerDataFormComponent } from "./forms/basic-retailer-data-form/basic-retailer-data-form.component";
import { RetailerContactInfoFormComponent } from "./forms/retailer-contact-info-form/retailer-contact-info-form.component";
import { RetailerStoresFormComponent } from "./forms/retailer-stores-form/retailer-stores-form.component";
import { TeamMembersFormComponent } from "./plentycore/steps/step1/forms/team-members-form/team-members-form.component";
import { MaterialModule } from "./material.module";
import { AlreadySelectedPipe } from './pipes/alreadySelectedPipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { TimerPipe } from './pipes/timer.pipe';
import { CheckerGalleryComponent } from './plentycore/checkers-screen/checker-gallery/checker-gallery.component';
import { CheckersScreenComponent } from './plentycore/checkers-screen/checkers-screen.component';
import { PltCheckerScreenBasicDataComponent } from './plentycore/checkers-screen/plt-checker-screen-basic-data/plt-checker-screen-basic-data.component';
import { PltCheckerScreenSurveyComponent } from './plentycore/checkers-screen/plt-checker-screen-survey/plt-checker-screen-survey.component';
import { PltCheckerScreenVisitPicturesComponent } from './plentycore/checkers-screen/plt-checker-screen-visit-pictures/plt-checker-screen-visit-pictures.component';
import { CreateProjectComponent } from "./plentycore/steps/step1/create-project/create-project.component";
import { DraftComponent } from './plentycore/draft/draft.component';
import { DraftsComponent } from "./plentycore/drafts/drafts.component";
import { HomeComponent } from "./plentycore/home/home.component";
import { LoginChangePasswordComponent } from "./plentycore/login/login-change-password/login-change-password.component";
import { LoginResetPasswordComponent } from "./plentycore/login/login-reset-password/login-reset-password.component";
import { LoginComponent } from "./plentycore/login/login.component";
import { LogoutComponent } from "./plentycore/logout/logout.component";
import { NewProjectComponent } from "./plentycore/steps/step1/new-project/new-project.component";
import { ProjectsOverviewComponent } from "./plentycore/projects-overview/projects-overview.component";
import { CreateContentProviderComponent, SelectContentProvider } from "./plentycore/steps/step2/content-providers";
import { ContentProviderEditArticleComponent } from './plentycore/steps/step2/content-providers/content-provider-edit-article/content-provider-edit-article.component';
import { ContentProviderEditContactComponent } from './plentycore/steps/step2/content-providers/content-provider-edit-contact/content-provider-edit-contact.component';
import { ContentProviderRootComponent } from './plentycore/steps/step2/content-providers/content-provider-root/content-provider-root.component';
import { CreateNewArticleComponent } from './plentycore/steps/step2/content-providers/create-new-article/create-new-article.component';
import { CreateNewContactComponent } from './plentycore/steps/step2/content-providers/create-new-contact/create-new-contact.component';
import { DetailsContentProvider } from './plentycore/steps/step2/content-providers/details-content-providers/details-content-provider.component';
import { ExistingArticlesComponent } from './plentycore/steps/step2/content-providers/details-content-providers/existing-articles/existing-articles.component';
import { CPBasicDataFormComponent } from "./plentycore/steps/step2/content-providers/forms/c-p-basic-data-form/c-p-basic-data-form.component";
import { ListContentProvider } from './plentycore/steps/step2/content-providers/list-content-provider/list-content-provider.component';
import { PartnersComponent } from './plentycore/steps/step2/partners/partners.component';
import { AddNewRetailerComponent } from "./plentycore/steps/step2/retailers/add-new-retailer/add-new-retailer.component";
import { CreateRetailerContactComponent } from "./plentycore/steps/step2/retailers/create-retailer-contact/create-retailer-contact.component";
import { EditRetailerContactComponent } from './plentycore/steps/step2/retailers/edit-retailer-contact/edit-retailer-contact.component';
import { ExistingRetailersComponent } from "./plentycore/steps/step2/retailers/existing-retailers/existing-retailers.component";
import { RetailerOverviewComponent } from "./plentycore/steps/step2/retailers/retailer-overview/retailer-overview.component";
import { RetailerRootComponent } from './plentycore/steps/step2/retailers/retailer-root/retailer-root.component';
import { ContactPersonSystemIntegratorComponent, EditSystemIntegrator, NewSystemIntegrator, SelectSystemIntegrator } from './plentycore/steps/step2/system-integrators';
import { ListSystemIntegratorsComponent } from './plentycore/steps/step2/system-integrators/list-system-integrators/list-system-integrators.component';
import { SystemIntegratorEditContactComponent } from './plentycore/steps/step2/system-integrators/system-integrator-edit-contact/system-integrator-edit-contact.component';
import { SystemIntegratorRootComponent } from './plentycore/steps/step2/system-integrators/system-integrator-root/system-integrator-root.component';
import { CreateFixtureComponent } from './plentycore/steps/step3/create-fixture/create-fixture.component';
import { DetailsFixtureArticleComponent } from './plentycore/steps/step3/details-fixture/details-fixture-article/details-fixture-article.component';
import { DetailsFixtureComponent } from './plentycore/steps/step3/details-fixture/details-fixture.component';
import { ExistingFixtureArticlesComponent } from './plentycore/steps/step3/details-fixture/existing-fixture-articles/existing-fixture-articles.component';
import { PlanogramFullscreenComponent } from './plentycore/steps/step3/details-fixture/planogram-fullscreen/planogram-fullscreen.component';
import { EditFixtureComponent } from './plentycore/steps/step3/edit-fixture/edit-fixture.component';
import { ExistingFixturesComponent } from './plentycore/steps/step3/existing-fixtures/existing-fixtures.component';
import { FixturesRootComponent } from './plentycore/steps/step3/fixtures-root/fixtures-root.component';
import { FixtureBasicDataFormComponent } from './plentycore/steps/step3/forms/fixture-basic-data-form/fixture-basic-data-form.component';
import { SelectedFixturesListComponent } from './plentycore/steps/step3/selected-fixtures-list/selected-fixtures-list.component';
import { SurveyLogicalJumpComponent } from './plentycore/steps/step4/survey-logical-jump/survey-logical-jump.component';
import { SurveyComponent } from './plentycore/steps/step4/survey/survey.component';
import { ExistingVisitPlanningComponent, ImportVisitPlanningComponent } from './plentycore/steps/step5';
import { QualitySurveyEditComponent, QualitySurveyOverviewComponent, QualitySurveyOverviewEmptyComponent } from './plentycore/steps/step6';
import { ProjectSummaryComponent } from './plentycore/steps/step7/project-summary/project-summary.component';
import { PltArticleComponent } from './plt-shared/plt-article/plt-article.component';
import { PltButtonCancelComponent } from "./plt-shared/plt-button-cancel/plt-button-cancel.component";
import { PltButtonCreateNewComponent } from "./plt-shared/plt-button-create-new/plt-button-create-new.component";
import { PltButtonEditComponent } from "./plt-shared/plt-button-edit/plt-button-edit.component";
import { PltButtonSaveComponent } from "./plt-shared/plt-button-save/plt-button-save.component";
import { PltCardBodiesComponent } from './plt-shared/plt-card-bodies/plt-card-bodies.component';
import { PltCardBodyComponent } from './plt-shared/plt-card-body/plt-card-body.component';
import { PltCardHeaderComponent } from './plt-shared/plt-card-header/plt-card-header.component';
import { PltCardComponent } from './plt-shared/plt-card/plt-card.component';
import { PltContactDetailComponent } from './plt-shared/plt-contact-detail/plt-contact-detail.component';
import { PltContactImportComponent } from './plt-shared/plt-contact-import/plt-contact-import.component';
import { PltContactPersonItemComponent } from "./plt-shared/plt-contact-person-item/plt-contact-person-item.component";
import { PltContactPersonListItemComponent } from './plt-shared/plt-contact-person-list-item/plt-contact-person-list-item.component';
import { PltContactPersonComponent } from "./plt-shared/plt-contact-person/plt-contact-person.component";
import { PltCounterComponent } from './plt-shared/plt-counter/plt-counter.component';
import { ClickOutsideDirective, PltCustomTableActionColumnComponent, PltCustomTableColumnComponent, PltCustomTableComponent, PltCustomTableEditableColumnComponent, PltCustomTableSelectColumnComponent, PltTableColumnComponent } from './plt-shared/plt-custom-table';
import { PltCustomTableCounterColumnComponent } from './plt-shared/plt-custom-table/plt-custom-table-counter-column/plt-custom-table-counter-column.component';
import { PltProvidersTableComponent } from "./plt-shared/plt-data-table/plt-providers-table/plt-providers-table.component";
import { PltRetailersTableComponent } from "./plt-shared/plt-data-table/plt-retailers-table/plt-retailers-table.component";
import { PltHeaderComponent } from "./plt-shared/plt-header/plt-header/plt-header.component";
import { PltImportCompareComponent } from "./plt-shared/plt-import-compare/plt-import-compare.component";
import { PltImportComponent } from "./plt-shared/plt-import/plt-import.component";
import { PltMatcardItemComponent } from './plt-shared/plt-matcard-item/plt-matcard-item.component';
import { PltBulletComponent } from "./plt-shared/plt-menu/plt-bullet/plt-bullet.component";
import { PltMenuItemComponent } from "./plt-shared/plt-menu/plt-menu-item/plt-menu-item.component";
import { PltMenuComponent } from "./plt-shared/plt-menu/plt-menu/plt-menu.component";
import { PltMultipleUpload } from './plt-shared/plt-multiple-upload/plt-multiple-upload.component';
import { PltOverviewIconComponent } from "./plt-shared/plt-overview-icon/plt-overview-icon.component";
import { PltDynamicPageHeaderDirective } from "./plt-shared/plt-page-header/plt-dynamic-header.directive";
import { PltDynamicPageService } from "./plt-shared/plt-page-header/plt-dynamic-header.service";
import { PltDynamicPageHeaderComponent } from "./plt-shared/plt-page-header/plt-dynamic-page-header.component";
import { pltPageHeaderComponent } from "./plt-shared/plt-page-header/plt-page-header.component";
import { PltProjectListComponent } from "./plt-shared/plt-project-list/plt-project-list.component";
import { PltProjectStatsComponent } from "./plt-shared/plt-project-overview/plt-project-stats/plt-project-stats.component";
import { PltProjectTitleComponent } from "./plt-shared/plt-project-overview/plt-project-title/plt-project-title.component";
import { PltSearchComponent } from "./plt-shared/plt-search/plt-search.component";
import { PltSingleArticleComponent } from './plt-shared/plt-single-article/plt-single-article.component';
import { PltSingleContactPersonComponent } from './plt-shared/plt-single-contact-person/plt-single-contact-person.component';
import { PltSinglePhotoInputComponent } from './plt-shared/plt-single-photo-input/single-photo-input.component';
import { PltSubHeaderComponent } from "./plt-shared/plt-sub-header/plt-sub-header.component";
import { ArticleService } from './services/article.service';
import { AuthGuard } from "./services/auth.guard";
import { RoleGuard } from "./services/role.guard";
import { AuthenticationService } from "./services/auth.service";
import { BasicDataService } from './services/basic-data.service';
import { ContactPersonService } from "./services/contact-person.service";
import { ContentProviderService } from "./services/content-provider.service";
import { DataserviceService } from "./services/dataservice.service";
import { FixturesService } from './services/fixtures.service';
import { NewprojectService } from "./services/newproject.service";
import { PltHelpservice } from "./services/plthelpers.service";
import { PltHttpService } from "./services/pltHttp.service";
import { ProjectService } from './services/project.service';
import { ProjectStatusProvider } from './services/projectStatus.provider';
import { QualitySurveyService } from './services/quality-survey.service';
import { RetailerSelectedGuard } from "./services/redirects/retailerRedirect.guard";
import { ContentProviderForProjectResolver } from './services/resolvers/contentProviderForProjectResolver';
import { ProjectGuidResolver } from './services/resolvers/projectGuidResolver';
import { FixtureListtResolver, ProjectDetailstResolver, RetailBasicDataResolver, RetailerListResolver, SurveyCheckResolver, SystemIntegratorsResolver } from "./services/resolvers/resolvers";
import { RetailerForProjectResolver } from './services/resolvers/retailerForProjectResolver';
import { SystemIntegratorForProjectResolver } from './services/resolvers/systemIntegratorForProjectResolver';
import { RetailerService } from './services/retailer.service';
import { PltSearchService } from "./services/search.service";
import { SystemIntegratorService } from "./services/system-integrator.service";
import { TeamMembersService } from "./services/team-members.service";
import { TokenInterceptor } from "./services/token.interceptor";
import { VisitPlanningService } from './services/visit-planning.service';
import { VisitSurveyService } from './services/visit-survey.service';
import { VisitService } from './services/visit.service';
import { PltCustomTableColumnDefinitionComponent } from './plt-shared/plt-custom-table/plt-custom-table-column-definition/plt-custom-table-column-definition.component';
import { PltCustomTablePositionColumnComponent } from './plt-shared/plt-custom-table/plt-custom-table-position-column/plt-custom-table-position-column.component';
import { ExchangeMerchandiserComponent } from './plentycore/steps/step5/exchange-merchandiser/exchange-merchandiser.component';
import { ExportProjectVisitsComponent } from './plentycore/export-project-visits/export-project-visits.component';
import { PltButtonExportComponent } from './plt-shared/plt-button-export/plt-button-export.component';
import { ExportVisitsService } from './services/exportVisits.service';
import { StoreDataDetailsComponent } from './plentycore/steps/step2/retailers/store-data-details/store-data-details.component';
import { StoreDataDetailsFormComponent } from './plentycore/steps/step2/retailers/forms/store-data-details-form/store-data-details-form.component';
import { PltImageItemComponent } from './plt-shared/plt-image-item/plt-image-item.component';
import { PltNoItemsComponent } from './plt-shared/plt-no-items/plt-no-items.component';
import { PltMessageComponent } from './plt-shared/plt-messages/plt-messages.component';
import { ReorderFunctionFormComponent } from './plentycore/steps/step1/forms/reorder-function-form/reorder-function-form.component';
import { MessagesService } from './plt-shared/plt-messages/plt-messages.service';
import { CustomDateAdapter } from './adaptors/custom-date-adapter';
import { ImportModalComponent } from './plentycore/steps/step2/retailers/import-modal/import-modal.component';
import { PltCheckerScreenExceptionsComponent } from './plentycore/checkers-screen/plt-checker-screen-exceptions/plt-checker-screen-exceptions.component';
import { BasicIntegratorDataFormComponent } from './plentycore/steps/step2/system-integrators/forms/basic-integrator-data-form/basic-integrator-data-form.component';
import { AdminComponent } from './plentycore/admin/admin.component';
import { LogComponent } from './plentycore/admin/log/log.component';
import { UserManagementComponent } from './plentycore/admin/user-management/user-management.component';
import { ExistingUsersComponent } from './plentycore/admin/user-management/existing-users/existing-users.component';
import { MerchandiserComponent } from './plentycore/admin/log/merchandiser/merchandiser.component';
import { StoreComponent } from './plentycore/admin/log/store/store.component';
import { AdminNavMenuComponent } from './plentycore/admin/admin-nav-menu/admin-nav-menu.component';
import { CreateUserComponent } from './plentycore/admin/user-management/create-user/create-user.component';
import { AdminService } from './services/admin.service';
import { DeleteUserModalComponent } from './plentycore/admin/user-management/delete-user-modal/delete-user-modal.component';
import { PltButtonDeleteComponent } from './plt-shared/plt-button-delete/plt-button-delete.component';
import { PltDataGridComponent } from './plt-shared/plt-data-grid/plt-data-grid.component';
import { SimpleAccordionComponent } from './plt-shared/simple-accordion/simple-accordion.component';
import { SimpleExpandableAreaComponent } from './plt-shared/simple-accordion/simple-expandable-area/simple-expandable-area.component';
import { SimpleExpandableHeaderActionsComponent } from './plt-shared/simple-accordion/simple-expandable-header-actions/simple-expandable-header-actions.component';
import { PltCopyProjectModalComponent } from './plt-shared/plt-copy-project-modal/plt-copy-project-modal.component';
import { PltExceptionsGridComponent } from './plt-shared/plt-exceptions-grid/plt-exceptions-grid.component';
import { PltDataRowComponent } from './plt-shared/plt-data-grid/plt-data-row/plt-data-row.component';
import { PltDataColumnSelectableComponent } from './plt-shared/plt-data-grid/plt-data-row/plt-data-column-selectable/plt-data-column-selectable.component';
import { PltDataColumnEditableComponent } from './plt-shared/plt-data-grid/plt-data-row/plt-data-column-editable/plt-data-column-editable.component';
import { PltDataColumnPopupComponent } from './plt-shared/plt-data-grid/plt-data-row/plt-data-column-popup/plt-data-column-popup.component';
import { PltIconComponent } from './plt-shared/plt-icon/plt-icon.component';
import { PltDataColumnComponent } from './plt-shared/plt-data-grid/plt-data-column/plt-data-column.component';
import { PltDataColumnTextComponent } from './plt-shared/plt-data-grid/plt-data-row/plt-data-column-text/plt-data-column-text.component';
import { PltDataColumnHeaderComponent } from './plt-shared/plt-data-grid/plt-data-column-header/plt-data-column-header.component';
import { PltDataColumnDropdownComponent } from './plt-shared/plt-data-grid/plt-data-row/plt-data-column-dropdown/plt-data-column-dropdown.component';
import { PltDataColumnDeletableComponent } from './plt-shared/plt-data-grid/plt-data-row/plt-data-column-deletable/plt-data-column-deletable.component';
import { PltDataColumnOrderComponent } from './plt-shared/plt-data-grid/plt-data-row/plt-data-column-order/plt-data-column-order.component';
import { SurveyDeleteQuestionsComponent } from './plentycore/steps/step4/survey-delete-questions/survey-delete-questions.component';
import { PltDatepickerComponent } from './plt-shared/plt-datepicker/plt-datepicker.component';
import { PltCheckboxComponent } from './plt-shared/plt-checkbox/plt-checkbox.component';
import { PltCustomTableCheckColumnComponent } from './plt-shared/plt-custom-table/plt-custom-table-check-column/plt-custom-table-check-column.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SurveyGridHeaderComponent } from './plentycore/steps/step4/survey/survey-grid-header/survey-grid-header.component';
import { PltDataColumnCounterComponent } from './plt-shared/plt-data-grid/plt-data-row/plt-data-column-counter/plt-data-column-counter.component';
import { SurveyCopyQuestionsComponent } from './plentycore/steps/step4/survey-copy-questions/survey-copy-questions.component';
import { ExportProjectVisitsOldComponent } from './plentycore/export-project-visits/export-project-visits-old/export-project-visits-old.component';
import { DeleteVisitPlanningComponent } from './plentycore/steps/step5/delete-visit-planning/delete-visit-planning.component';
import { EmergencyDataTransferModalComponent } from './plentycore/admin/user-management/emergency-data-transfer-modal/emergency-data-transfer-modal.component';
import { SurveyCopyModalComponent } from './plentycore/steps/step4/survey-copy-modal/survey-copy-modal.component';
import { DeleteStorePhotoModalComponent } from './plentycore/checkers-screen/delete-store-photo-modal/delete-store-photo-modal.component';
import { TagService } from './services/tag.service';
import { PltVisitsComponent } from './plt-shared/plt-visits/plt-visits.component';
import { PltExportStripComponent } from './plt-shared/plt-export-strip/plt-export-strip.component';
import { SurveyQuestionPageComponent } from './plentycore/steps/step4/survey-question-page/survey-question-page.component';


export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en').subscribe();
}

@NgModule({
  declarations: [
    AppComponent,
    PltHeaderComponent,
    PltDynamicPageHeaderComponent,
    PltDynamicPageHeaderDirective,
    PltMenuComponent,
    ProjectsOverviewComponent,
    LoginComponent,
    HomeComponent,
    PltMenuItemComponent,
    PltBulletComponent,
    PltSearchComponent,
    PltProjectListComponent,
    LoginResetPasswordComponent,
    LoginChangePasswordComponent,
    LogoutComponent,
    CreateProjectComponent,
    CreateProjectComponent,
    PltProjectTitleComponent,
    PltProjectStatsComponent,
    PltButtonCreateNewComponent,
    PltButtonSaveComponent,
    PltButtonEditComponent,
    PltButtonCancelComponent,
    DraftsComponent,
    PltOverviewIconComponent,
    ScrollDirective,
    PltProvidersTableComponent,
    BasicProjDataFormComponent,
    NewProjectComponent,
    TeamMembersFormComponent,
    pltPageHeaderComponent,
    ExistingRetailersComponent,
    PltRetailersTableComponent,
    AddNewRetailerComponent,
    BasicRetailerDataFormComponent,
    RetailerOverviewComponent,
    PltSubHeaderComponent,
    RetailerStoresFormComponent,
    RetailerContactInfoFormComponent,
    CreateRetailerContactComponent,
    PltImportComponent,
    PltImportCompareComponent,
    PltContactPersonItemComponent,
    PltContactDetailComponent,
    NewSystemIntegrator,
    EditSystemIntegrator,
    SelectSystemIntegrator,
    PartnersComponent,
    SelectContentProvider,
    CreateContentProviderComponent,
    PltSinglePhotoInputComponent,
    PltMultipleUpload,
    ListContentProvider,
    DetailsContentProvider,
    DraftComponent,
    PltCardComponent,
    PltCardHeaderComponent,
    PltCardBodyComponent,
    PltContactImportComponent,
    PltCardBodiesComponent,
    CPBasicDataFormComponent,
    PltContactPersonComponent,
    EditRetailerContactComponent,
    BasicIntegratorDataFormComponent,
    PltArticleComponent,
    PltCustomTableComponent,
    PltTableColumnComponent,
    PltCustomTableActionColumnComponent,
    PltCustomTableColumnComponent,
    PltCustomTableEditableColumnComponent,
    PltCustomTableSelectColumnComponent,
    ClickOutsideDirective,
    PltArticleComponent,
    CreateNewArticleComponent,
    CreateNewContactComponent,
    PltContactPersonListItemComponent,
    ContactPersonSystemIntegratorComponent,
    ExistingFixturesComponent,
    CreateFixtureComponent,
    FixtureBasicDataFormComponent,
    PltSingleContactPersonComponent,
    ContentProviderEditContactComponent,
    EditFixtureComponent,
    DetailsFixtureComponent,
    ExistingArticlesComponent,
    PltMatcardItemComponent,
    ListSystemIntegratorsComponent,
    PltSingleArticleComponent,
    ContentProviderEditArticleComponent,
    SurveyComponent,
    SystemIntegratorEditContactComponent,
    SelectedFixturesListComponent,
    ExistingVisitPlanningComponent,
    ImportVisitPlanningComponent,
    DetailsFixtureArticleComponent,
    ExistingFixtureArticlesComponent,
    PltCounterComponent,
    PltCustomTableCounterColumnComponent,
    QualitySurveyEditComponent,
    QualitySurveyOverviewComponent,
    QualitySurveyOverviewEmptyComponent,
    ProjectSummaryComponent,
    SurveyLogicalJumpComponent,
    CheckersScreenComponent,
    PltCheckerScreenBasicDataComponent,
    PltCheckerScreenVisitPicturesComponent,
    PltCheckerScreenSurveyComponent,
    CheckerGalleryComponent,
    RetailerRootComponent,
    SystemIntegratorRootComponent,
    ContentProviderRootComponent,
    TimerPipe,
    AlreadySelectedPipe,
    TranslatePipe,
    PlanogramFullscreenComponent,
    FixturesRootComponent,
    PltCustomTableColumnDefinitionComponent,
    PltCustomTablePositionColumnComponent,
    ExchangeMerchandiserComponent,
    ExportProjectVisitsComponent,
    PltButtonExportComponent,
    StoreDataDetailsComponent,
    StoreDataDetailsFormComponent,
    PltImageItemComponent,
    PltNoItemsComponent,
    ReorderFunctionFormComponent,
    PltMessageComponent,
    ImportModalComponent,
    PltCheckerScreenExceptionsComponent,
    AdminComponent,
    LogComponent,
    UserManagementComponent,
    ExistingUsersComponent,
    MerchandiserComponent,
    StoreComponent,
    AdminNavMenuComponent,
    CreateUserComponent,
    DeleteUserModalComponent,
    PltButtonDeleteComponent,
    PltDataGridComponent,
    SimpleAccordionComponent,
    SimpleExpandableAreaComponent,
    SimpleExpandableHeaderActionsComponent,
    PltDataRowComponent,
    PltDataColumnSelectableComponent,
    PltDataColumnEditableComponent,
    PltDataColumnPopupComponent,
    PltIconComponent,
    PltDataColumnComponent,
    PltDataColumnTextComponent,
    PltDataColumnHeaderComponent,
    PltCopyProjectModalComponent,
    PltExceptionsGridComponent,
    PltDataColumnDeletableComponent,
    PltDataColumnOrderComponent,
    SurveyDeleteQuestionsComponent,
    PltDataColumnDropdownComponent,
    SurveyGridHeaderComponent,
    PltDataColumnDropdownComponent,
    PltDatepickerComponent,
    PltCheckboxComponent,
    PltCustomTableCheckColumnComponent,
    PltDataColumnCounterComponent,
    SurveyCopyQuestionsComponent,
    ExportProjectVisitsOldComponent,
    DeleteVisitPlanningComponent,
    EmergencyDataTransferModalComponent,
    SurveyCopyModalComponent,
    DeleteStorePhotoModalComponent,
    PltVisitsComponent,
    PltExportStripComponent,
    SurveyQuestionPageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NoopAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatExpansionModule,
    MatCardModule,
    FlexLayoutModule,
    CdkTableModule,
    MatDividerModule,
    ScrollDispatchModule,
    DragDropModule
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    RetailerSelectedGuard,
    AuthenticationService,
    PltHttpService,
    PltSearchService,
    DataserviceService,
    PltHelpservice,
    NewprojectService,
    TeamMembersService,
    VisitPlanningService,
    SystemIntegratorService,
    BasicDataService,
    RetailerService,
    FixturesService,
    PltDynamicPageService, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // RESOLVERS
    NewprojectService,
    RetailBasicDataResolver,
    RetailerListResolver,
    ProjectGuidResolver,
    SystemIntegratorsResolver,
    FixtureListtResolver,
    ProjectDetailstResolver,
    SystemIntegratorForProjectResolver,
    ContentProviderForProjectResolver,
    RetailerForProjectResolver,
    ProjectService,
    ArticleService,
    ContentProviderService,
    ContactPersonService,
    VisitSurveyService,
    ProjectStatusProvider,
    QualitySurveyService,
    SurveyCheckResolver,
    VisitService,
    ExportVisitsService,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    MessagesService,
    AdminService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    TagService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    PltButtonCreateNewComponent,
    PltButtonSaveComponent,
    PltButtonEditComponent,
    PltButtonCancelComponent,
    QualitySurveyEditComponent,
    SurveyLogicalJumpComponent,
    CheckerGalleryComponent,
    ExchangeMerchandiserComponent,
    ExportProjectVisitsComponent,
    PlanogramFullscreenComponent,
    ImportModalComponent,
    DeleteUserModalComponent,
    DeleteStorePhotoModalComponent,
    PltCopyProjectModalComponent,
    SurveyDeleteQuestionsComponent,
    SurveyCopyQuestionsComponent,
    ExportProjectVisitsOldComponent,
    DeleteVisitPlanningComponent,
    EmergencyDataTransferModalComponent,
    SurveyCopyModalComponent
  ],
})
export class AppModule { }

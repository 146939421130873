import { DeleteUserModalComponent } from './../delete-user-modal/delete-user-modal.component';
import { ActiveTableEntry } from './../../../../plt-shared/plt-custom-table/plt-custom-table/plt-custom-table.component';
import { UserService } from './../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetails } from "./../../../../models/new-model/user";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AdminService } from "../../../../../app/services/admin.service";
import { CustomTableDataSource } from "../../../../../app/plt-shared/plt-custom-table/custom-table-data-source";
import { SelectableTableEntry } from "../../../../../app/plt-shared/plt-custom-table/plt-custom-table/plt-custom-table.component";
import { PltSearchService } from '../../../../services/search.service';
import { MatDialog } from '@angular/material';
import { EmergencyDataTransferModalComponent } from '../emergency-data-transfer-modal/emergency-data-transfer-modal.component';

@Component({
  selector: "existing-users",
  templateUrl: "./existing-users.component.html",
  styleUrls: ["./existing-users.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ExistingUsersComponent implements OnInit {
  blockClass = "fas fa-ban";
  deleteClass = "fas fa-trash-alt relative";
  eyeClass = "far fa-eye";
  swapBlockClass = "fas fa-ban active";
  handShareClass = "fas fa-hands-helping";

  dataSource: UsersDataSource = new UsersDataSource([]);

  constructor(private adminService: AdminService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private searchService: PltSearchService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.adminService.getUsers().subscribe((users: UserDetails[]) => {
      this.updateDataSource(users);
    });
  }

  searchForUsers(term: string) {
    this.searchService.searchInput(
      'auth/users/detailed',
      { query: term }
    ).subscribe((response: { data }) =>
      this.updateDataSource(response.data.users
        .map(u => new UserDetails().deserialize(u))
      )
    );
  }

  updateDataSource(users: UserDetails[]) {
    const dataSourceItems = users.map(
      (user: UserDetails) => new UserTableItem(user)
    );

    this.dataSource = new UsersDataSource(dataSourceItems);
  }

  onBlockUser(item) {
    if (item.active) {
      this.userService.blockUser(item.guid).subscribe((user: UserDetails) => {
      });
    } else {
      this.userService.unblockUser(item.guid).subscribe((user: UserDetails) => {
      });
    }
    item.active = !item.active;
  }

  onEyeClick(item: any) {
    this.router.navigate([`./view-user/${item.guid}`], {
      relativeTo: this.activeRoute
    });
  }

  onUserDelete(item) {
    this.openDeleteModal(item);
  }

  onEmergencyDataTransfer(item: any) {
    this.openEmergencyDataTransferModal(item);
  }

  private openDeleteModal(item: UserTableItem) {
    const dialogRef = this.dialog.open(DeleteUserModalComponent, {
      width: '600px',
      panelClass: 'delete-user-dialog',
      data: item
    });

    dialogRef.afterClosed().subscribe(value => {
      this.adminService.getUsers().subscribe((users: UserDetails[]) => {
        this.updateDataSource(users);
      });

    });
  }

  private openEmergencyDataTransferModal(item: UserTableItem) {
    this.dialog.open(EmergencyDataTransferModalComponent, {
      width: '600px',
      panelClass: 'emergency-data-transfer-dialog',
      data: item
    });
  }
}
export class UsersDataSource extends CustomTableDataSource<UserTableItem> { }

export class UserTableItem extends SelectableTableEntry implements ActiveTableEntry {
  guid: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  active: boolean;
  userID: string;

  constructor(u: UserDetails) {
    super();
    this.guid = u.account.guid;
    this.firstName = u.contactPerson ? u.contactPerson.firstName : '';
    this.lastName = u.contactPerson ? u.contactPerson.lastName : '';
    this.username = u.account.username;
    this.email = u.account.email;
    this.active = u.account.active;
    this.userID = !!u.account.ba_ds_id ?  u.account.ba_ds_id : "No ID"; 
  }

  isActive(): boolean {
    return this.active;
  }


}

import { Serializable } from "../new-model/serializable";

export class Merchandiser implements Serializable<Merchandiser> {

    accountTypesId: number;

    email: string;

    guid: string;

    name: string;

    username: string;

    deserialize(input: any): Merchandiser {
        return <Merchandiser>{
            accountTypesId: input.account_types_id || 0,
            email: input.email || '',
            guid: input.guid || '',
            name: input.name || '',
            username: input.username || ''
        };
    }

    serialize(): any {
        return {
            account_types_id: this.accountTypesId,
            email: this.email,
            guid: this.guid,
            name: this.name,
            username: this.username
        };
    }
}

import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { CustomFormComponent } from '../customForm';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'basic-retailer-data-form',
  templateUrl: './basic-retailer-data-form.component.html',
  styleUrls: ['./basic-retailer-data-form.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => BasicRetailerDataFormComponent)
    }
  ],
})
export class BasicRetailerDataFormComponent implements OnInit, CustomFormComponent {

  @Input() isEditMode: boolean;
  @Input() layout_mode: string;
  @Input() retailerCategoryList: JSON;
  @Input('model') model: any;
  @Output() basicRValues = new EventEmitter<object>();
  @ViewChild('basicRetailerDataForm') basicDataForm: NgForm;

  imageSrc = '';
  postalCodePattern = new RegExp("/^\d+$/")
  constructor() {
  }

  ngOnInit() {
  }

  isFormValid() {
    return !!this.basicDataForm ? this.basicDataForm.valid : false;
  }

  setCategory(newValue) {
    this.model.category = newValue;
    this.basicRValues.emit(this.model);
  }

  setStreet(newValue) {
    this.model.address.street = newValue;
    this.basicRValues.emit(this.model);
  }

  setZip(newValue) {
    

      this.model.address.zip = newValue;
      this.basicRValues.emit(this.model)
  
    
  }

  setCity(newValue) {
    this.model.address.city = newValue;
    this.basicRValues.emit(this.model)
  }

  setCountry(newValue) {
    this.model.address.country = newValue;
    this.basicRValues.emit(this.model)
  }

  setName(newValue) {
    this.model.name = newValue;
    this.basicRValues.emit(this.model);
  }

  setRegion(newValue) {
    this.model.address.region = newValue;
    this.basicRValues.emit(this.model);
  }

  onImageUpload(binaryImageDataEvent: any) {
    this.model.logo = binaryImageDataEvent;
    this.basicRValues.emit(this.model);
  }

  // this needs to be replaced by the Address method once refactoring takes place and Address model object is used
  private joinAddressStrings(root: string, element: string): string {
    const rootElement = root === undefined ? '' : root;
    const addrElement = element === undefined ? '' : element;

    if (rootElement === '') {
      return addrElement;
    }
    else if (addrElement === '') {
      return rootElement;
    }
    else {
      return rootElement + ", " + addrElement;
    }
  }

  getAddressString() {
    return this.joinAddressStrings(this.joinAddressStrings(this.joinAddressStrings(this.joinAddressStrings(this.model.address.street, this.model.address.zip),
      this.model.address.city), this.model.address.region), this.model.address.country);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timerDisplay' })
export class TimerPipe implements PipeTransform {
    transform(val: number) {
        var seconds = val % 60;
        var mins = Math.floor(val / 60);
        if (seconds < 10) {
            return mins + ":0" + seconds;
        }
        else {
            return mins + ":" + seconds;
        }
    }
}
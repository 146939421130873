import { DataserviceService } from "./../../services/dataservice.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"]
})
export class AdminComponent implements OnInit {

  constructor(private dataService: DataserviceService) {}

  ngOnInit() {
    this.dataService.setPageHeaderTitle("Administration");
  }
}

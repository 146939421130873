import { Type } from './../new-model/type';
import { Serializable } from './../new-model/serializable';
import { Category } from '../new-model/category';
import { SurveyQuestionChoice } from './surveyQuestionChoice';
import { Fixture } from '../new-model/fixture';
import { TagStub } from '../new-model/tag';

export class SurveyQuestion implements Serializable<SurveyQuestion> {
    id: number;
    guid: string;
    question: string;
    questionReport: string;
    isEntry: boolean;
    category: Category;
    answerType: Type;
    questionType: Type;
    tag?: TagStub[];
    sort?: number;
    fixture?: Fixture;
    choices?: SurveyQuestionChoice[];
    timer?: number;
    fixtureId: string;
    isActive: boolean;
    isSelected: boolean;
    surveySpecificFixture?: Fixture;
    subType?: string;

    deserialize(element: any) {
        this.id = element.id || 0;
        this.guid = element.guid || '';
        this.question = element.question || '';
        this.questionReport = element.question_report || '';
        this.isEntry = element.is_entry || false;
        this.category = element.survey_question_category ? new Category().deserialize(element.survey_question_category) : null;
        this.answerType = element.survey_question_answer_type ? new Type().deserialize(element.survey_question_answer_type) : null;
        this.questionType = element.survey_question_type ? new Type().deserialize(element.survey_question_type) : null;
        this.sort = element.sort || 0;
        this.fixture = element.fixture || null;
        this.fixtureId = element.fixture_guid || null;
        this.choices = (element.survey_question_choices instanceof Array) ?
            element.survey_question_choices.map(choice => new SurveyQuestionChoice().deserialize(choice)) : [];
        this.timer = element.timer;
        this.isActive = element.is_active || false;
        this.isSelected = element.is_select || false;
        this.surveySpecificFixture = element.survey_specific_fixture ? new Fixture().deserialize(element.survey_specific_fixture) : null;
        this.tag = element.tag ? element.tag.map(tag => new TagStub().deserialize(tag)) : [];
        this.subType = element.sub_type || '';
        return this;
    }

    serialize() {
        return {
            id: this.id,
            guid: this.guid,
            question: this.question,
            question_report: this.questionReport,
            is_entry: this.isEntry,
            survey_question_category: this.category.serialize(),
            survey_question_answer_type: this.answerType.serialize(),
            survey_question_type: this.questionType.serialize(),
            sort: this.sort,
            fixture: this.fixture,
            fixture_guid: this.fixtureId,
            survey_question_choices: this.choices.map(choice => new SurveyQuestionChoice().serialize()),
            timer: this.timer,
            is_active: this.isActive,
            survey_specific_fixture: this.surveySpecificFixture,
            tag: this.tag.map(tag => tag.serialize()),
            sub_type: this.subType
        }
    }
}





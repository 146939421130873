import { Component, OnInit, Input } from '@angular/core';
import { contactDetail } from '../../models/contactDetailModel';
import { contactForm } from '../../models/contactFormModel';

@Component({
  selector: 'retailer-contact-info-form',
  templateUrl: './retailer-contact-info-form.component.html',
  styleUrls: ['./retailer-contact-info-form.component.scss']
})
export class RetailerContactInfoFormComponent implements OnInit {
  @Input() mode: boolean;
  @Input() app_mode: string;
  @Input('') model: contactForm;

  RetailerSalutationList = ["Frau", 'Herr'];
  contactDetailDefault: contactDetail[];

  constructor() { }

  ngOnInit() {
    if (!this.model) {
      //this.model=[];
    }

  }

  setSalutation(e) {
    this.model.salutation = e;
  }

  setFirstName(e) {
    this.model.first_name = e;
  }

  setLastName(e) {
    this.model.last_name = e;
  }

  setPosition(e) {
    this.model.position = e;
  }

  setDepartment(e) {
    this.model.department = e;
  }

  setMailAddress(e) {
    let emailAddress: contactDetail;
    emailAddress = {
      type: 'Mail address',
      value: e,
    };
    //this.contactDetailDefault.push(emailAddress);
  }

  setMobilePhone(e) {
    let mobilePhone: contactDetail;
    mobilePhone = {
      type: 'Mobile phone',
      value: e,
    };
    //this.contactDetailDefault.push(mobilePhone);
  }

  setWorkPhone(e) {
    let workPhone: contactDetail;
    workPhone = {
      type: 'Work phone',
      value: e,
    };
    // this.contactDetailDefault.push(workPhone);
  }

  addOneContactDetail() {
    //add new contact
    this.model.communication_details.push(new contactDetail());
  }

}

import { Serializable } from "./serializable";
import { Type } from "./type";
import { Article, Brand } from "./content-provider";

export class Fixture implements Serializable<Fixture> {
    guid: string;
    name: string;
    googleName?: string;
    active: boolean;
    planogram: string;
    orderPlanogram: string;
    photoCountMin: number;
    photoCountMax: number;
    fixtureType: FixtureType;


    deserialize(input) {
        this.guid = input.guid;
        this.name = input.name;
        this.googleName = input.google_name ? input.google_name : '';
        this.active = input.active;
        this.planogram = input.planogram ? input.planogram.pict : '';
        this.orderPlanogram = input.order_planogram ? input.order_planogram.pict : '';
        this.photoCountMin = input.photo_count_min;
        this.photoCountMax = input.photo_count_max;
        this.fixtureType = new FixtureType().deserialize(input.fixture_type || {});
        return this;
    }

    serialize() {
        return {
            guid: this.guid,
            name: this.name,
            google_name: this.googleName,
            active: this.active,
            planogram: this.planogram,
            order_planogram: this.orderPlanogram,
            photo_count_min: this.photoCountMin,
            photo_count_max: this.photoCountMax,
            fixture_type: this.fixtureType.serialize()
        }
    }
}

export class FixtureType extends Type {
    guid: string;
    active: boolean;
    slug: string;

    constructor(id?: number, name?: string, guid?: string, active?: boolean) {
        super(id, name);
        this.guid = guid;
        this.active = active;
    }

    deserialize(input) {
        super.deserialize(input);
        this.guid = input.guid;
        this.active = input.active;
        this.slug = input.slug;
        return this;
    }

    serialize() {
        return {
            id: this.id,
            name: this.name,
            guid: this.guid,
            active: this.active
        };
    }

}

export class FixtureArticle extends Article {
    facings: number = 0;
    cardCountingMode: CardCountingMode;
    brand: Brand;
    
    facingCountingMode: CardCountingMode;

    deserialize(input) {
        super.deserialize(input);
        this.reorder = input.reorder === undefined ? this.reorder : input.reorder;
        this.currency = input.currency === undefined ? this.currency : input.currency;
        this.facings = input.facings ? input.facings : 0;
        this.cardCountingMode = new CardCountingMode().deserialize(input.card_counting_mode || input.cardCountingMode || {});
        this.brand = input.brand ? new Brand().deserialize(input.brand) : new Brand();
        this.facingCountingMode = new CardCountingMode().deserialize(
            input.facing_counting_mode ||
            input.facingCountingMode ||
            input.article_counting_mode ||
            input.articleCountingMode ||
            {}
        );
        return this;
    }

    serialize() {
        return {
            guid: this.guid,
            name: this.name,
            active: this.selected,
            face_value: this.faceValue,
            number: this.number,
            article_currency: this.currency,
            size: this.size,
            edi: this.edi,
            is_order: this.reorder,
            article_category: this.articleCategory.serialize(),
            article_type: this.articleType.serialize(),
            article_photos: this.articlePhotos ? this.articlePhotos.map(ap => ap.serialize()) : '',
            facings: this.facings,
            card_counting_mode: this.cardCountingMode ? this.cardCountingMode.serialize() : '',
            article_counting_mode: this.articleCountingMode,
            brand_id: this.brand.guid,
            brand_logo: this.brand.logo,
            facing_counting_mode: this.facingCountingMode
        };
    }

    getCountingMode(): CardCountingMode {
        return this.cardCountingMode;
    }

    getArticleCountingMode(): CardCountingMode {
        return this.articleCountingMode;
    }

    getFacingCountingMode(): CardCountingMode {
        return this.facingCountingMode;
    }

}

export class CardCountingMode {
    id: string;
    value: string;
    slug: string;

    deserialize(input) {
        this.id = input.id;
        this.value = input.value;
        this.slug = input.slug;
        return this;
    }

    serialize() {
        return {
            id: this.id,
            value: this.value,
            slug: this.slug
        }
    }
}
import { Serializable } from "../new-model/serializable";
import { Merchandiser } from "./merchandiser";
import { Teamlead } from "./teamlead";
import { Store } from "./store";

export class VisitPlanning implements Serializable<VisitPlanning>{

    store: Store;

    merchandiser: Merchandiser;

    teamlead: Teamlead;

    guid: string;

    deserialize(input: any): VisitPlanning {
        return <VisitPlanning> {
            store: input.store ? new Store().deserialize(input.store) : null,
            merchandiser: input.merchandiser ? new Merchandiser().deserialize(input.merchandiser) : null,
            teamlead: input.teamlead ? new Teamlead().deserialize(input.teamlead) : null,
            guid: input.guid
        };
    }

    serialize() {
        return {
            store: this.store.serialize(),
            merchandiser: this.merchandiser.serialize(),
            teamLead: this.teamlead.serialize(),
            guid: this.guid
        };
    }
}
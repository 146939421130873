
export const VisitStates = [
    {
        "name": "All stores",        
        "status": "ALL"
    },
    {
        "name": "Stores with status OK",        
        "status": "OK"
    },
    {
        "name": "Stores with status NOK",        
        "status": "NOT_OK"
    },
    {
        "name": "Stores with status Not checked",        
        "status": "NOT_CHECKED"
    },
    {
        "name": "Stores with status Open",        
        "status": "OPEN"
    }
]

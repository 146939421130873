import { Serializable } from "../new-model/serializable";
import { Retailer } from "../new-model/retailer";
import { SystemIntegrator } from "../new-model/system-integrator";
import { ContentProvider } from "../new-model/content-provider";
import { Status } from "./status";
import { Type } from "./type";
import { Fixture } from "./fixture";

export class Project implements Serializable<Project>{
    guid: string;
    name: string;
    active: boolean;
    start: ProjectDate;
    end: ProjectDate;
    hasQualityControl: boolean;
    hasOrder: boolean;
    hasQRcode: boolean = false;
    projectType: Type;
    projectStatus: Status;
    inReview: boolean;
    inTesting: boolean;
    projectAccounts: Map<string, ProjectAccountDetails[]>;
    doneVisits: string;
    states: State[];
    businessPartners: BusinessPartners;
    fixtures: Fixture[];
    hasVisits;
    parentGuid: string;
    tooManyFacingException: number;


    deserialize(input) {
        this.guid = input.guid;
        this.name = input.name;
        this.active = input.active;
        this.start = new ProjectDate().deserialize(input.start);
        this.end = new ProjectDate().deserialize(input.end);
        this.hasQualityControl = input.has_quality_control;
        this.hasOrder = input.has_order;
        this.hasQRcode = input.qr_code;
        this.projectType = new Type().deserialize(input.project_type);
        this.projectStatus = new Status().deserialize(input.project_status);
        this.inReview = input.in_review;
        this.inTesting = input.in_testing;
        this.states = State.buildStateArray(input.states || {});

        this.projectAccounts = new Map<string, ProjectAccountDetails[]>();
        for (var prop in input.project_accounts) {
            if (input.project_accounts.hasOwnProperty(prop)) {
                this.projectAccounts.set(prop, input.project_accounts[prop].map(json => new ProjectAccountDetails().deserialize(json)));
            }
        }

        this.doneVisits = input.done_visits;
        this.fixtures = input.fixtures ? input.fixtures.map(fixt => new Fixture().deserialize(fixt)) : new Fixture();
        this.businessPartners = input.business_partners ? new BusinessPartners().deserialize(input.business_partners) : new BusinessPartners();
        this.hasVisits = input.has_visits;
        this.parentGuid = input.parent_guid;
        this.tooManyFacingException = input.too_many_facing_exception;
        return this;
    }

    serialize() {
        return {
            guid: this.guid,
            name: this.name,
            active: this.active,
            start: this.start.serialize(),
            end: this.end.serialize(),
            has_quality_control: this.hasQualityControl,
            has_order: this.hasOrder,
            qr_code: this.hasQRcode,
            project_type: this.projectType.serialize(),
            project_status: this.projectStatus.serialize(),
            in_review: this.inReview,
            in_testing: this.inTesting,

            team_members: {  // TODO - unify field names coming from BackEnd (once it's project_accounts, and some other time it's team_members)
                projectmanager: this.projectAccounts.get(ProjectAccountRoles.projectManager).map(pa => pa.serialize()),
                projectagent: this.projectAccounts.get(ProjectAccountRoles.projectAgent).map(pa => pa.serialize()),
                fieldmanager: this.projectAccounts.get(ProjectAccountRoles.fieldManager).map(pa => pa.serialize()),
                qualitymanager: this.projectAccounts.get(ProjectAccountRoles.qualityManager).map(pa => pa.serialize()),
                qualityagent: this.projectAccounts.get(ProjectAccountRoles.qualityAgent).map(pa => pa.serialize())
            },
            done_visits: this.doneVisits,
            states: this.states,
            business_partners: this.businessPartners.serialize(),
            fixtures: this.fixtures.map(fixt => fixt.serialize()),
            has_visits: this.hasVisits,
            parent_guid: this.parentGuid,
            too_many_facing_exception: this.tooManyFacingException
        }
    }

    isMysteryShopping(): boolean {
        return this.projectType.name === 'Mystery Shopping';
    }
    isSurveyOnly(): boolean {
        return this.projectType.name === 'Survey Only';
    }
    isInReview(): boolean {
        return this.inReview;
    }
    isInTesting(): boolean {
        return this.inTesting;
    }

    //for future
    isRunning(): boolean {
        return this.projectStatus.name === 'Running';
    }
    //for future
    isDraft(): boolean {
        return this.projectStatus.name === 'Draft'
    }

    canBeCopiedOver(): boolean {
        return !this.parentGuid && !this.businessPartners.contentProviders.length && !this.businessPartners.systemIntegrators.length;
    }

    getPageTitle(): any {

        if (this.isRunning()) {
            return {
                title: this.name,
                subtitle: "running"
            }
        }

        if (this.isInTesting()) {
            return {
                title: "Project testing",
                subtitle: ""
            }
        }

        if (this.isInReview()) {
            return {
                title: "Project review",
                subtitle: ""
            }
        }

        if (this.isDraft()) {
            return {
                title: this.name,
                subtitle: ""
            }
        }
    }

}

export class ProjectDate implements Serializable<ProjectDate>{
    date: string;
    timezoneType: number;
    timezone: string;

    deserialize(input) {
        this.date = input.date;
        this.timezoneType = input.timezone_type;
        this.timezone = input.timezone;
        return this;
    }

    serialize() {
        return {
            date: this.date,
            timezone_type: this.timezoneType,
            timezone: this.timezone
        }
    }
}

export class ProjectAccountDetails implements Serializable<ProjectAccountDetails>{
    accountTypesId: number;
    guid: string;
    username: string;
    email: string;
    name: string;
    active: boolean;
    ba_ds_id: string;

    constructor(jsonObject?: any) {
        if (jsonObject != undefined) {
            this.accountTypesId = jsonObject.account_types_id;
            this.guid = jsonObject.guid;
            this.username = jsonObject.username;
            this.email = jsonObject.email;
            this.name = jsonObject.name;
            this.active = jsonObject.active;
            this.ba_ds_id = jsonObject.ba_ds_id;
        }
        else {
            this.accountTypesId = undefined;
            this.guid = undefined;
            this.username = undefined;
            this.email = undefined;
            this.name = undefined;
            this.active = undefined;
            this.ba_ds_id = undefined;
        }
    }

    deserialize(input) {
        this.accountTypesId = input.account_types_id;
        this.email = input.email;
        this.guid = input.guid;
        this.name = input.name;
        this.username = input.username;
        this.active = input.active;
        this.ba_ds_id = input.ba_ds_id;
        return this;
    }

    serialize() {
        return {
            account_types_id: this.accountTypesId,
            guid: this.guid,
            username: this.username,
            email: this.email,
            name: this.name,
            active: this.active,
            ba_ds_id: this.ba_ds_id,
        }
    }
}

export const enum ProjectAccountRoles {
    master = "master", admin = "admin", projectLeader = "projectleader", projectManager = "projectmanager", projectAgent = "projectagent", fieldManager = "fieldmanager",
    regionManager = "regionmanager", qualityManager = "qualitymanager", qualityAgent = "qualityagent", checkerManager = "checkermanager", checker = "checker",
    merchandiser = "merchandiser", customer = "customer"
}

export class BusinessPartners implements Serializable<BusinessPartners> {
    retailer: Retailer;
    systemIntegrators: SystemIntegrator[];
    contentProviders: ContentProvider[];

    deserialize(input) {
        this.retailer = input.retailer ? new Retailer().deserialize(input.retailer) : new Retailer();
        this.systemIntegrators = input.system_integrators ? input.system_integrators.map(si => new SystemIntegrator().deserialize(si)) : [];
        this.contentProviders = input.content_providers ? input.content_providers.map(cp => new ContentProvider().deserialize(cp)) : [];
        return this;
    }

    serialize() {
        return {
            retailer: this.retailer.serialize(),
            system_integrators: this.systemIntegrators.map(si => si.serialize()),
            content_providers: this.contentProviders.map(cp => cp.serialize())
        }
    }
}

export class State {
    static decoder: any = {
        ok: 'OK',
        not_ok: 'NOK',
        not_checked: 'Not checked',
        open: 'Open',
        total: 'Total',
    };

    message: string;
    value: number;
    key: string;

    constructor(message, value, key) {
        this.message = message;
        this.value = value;
        this.key = key;
    }

    static buildStateArray(input) {
        const keys = Object.keys(input);
        return keys.map(key => {
            const value = input[key];
            const message = State.decoder[key];

            return new State(message, value, key);
        });
    }


}

export class ProjectVisitDetails {
    project: Project;
    id: number;

    constructor(project: Project, idx: number) {
        this.project = project;
        this.id = idx;
    }
}

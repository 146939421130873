import { Serializable } from "../new-model/serializable";
import { VisitPlanning } from "./visitPlanning";

export class VisitPlannings implements Serializable<VisitPlannings> {

    db: VisitPlanning[];

    conflicts: VisitPlanning[];

    deserialize(input: any): VisitPlannings {
        return <VisitPlannings> {
            db: input.db.map(visit => new VisitPlanning().deserialize(visit)),
            conflicts: input.conflicts.map(visit => new VisitPlanning().deserialize(visit))
        };
    }

    serialize() {
        return {
            db: this.db.map(visit => visit.serialize()),
            conflicts: this.conflicts.map(visit => visit.serialize())
        };
    }
}

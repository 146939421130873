export class SurveyQuestionJump {
    guid?: string;
    questionChoiceGuid: string;
    questionGuid?: string;
    end?: boolean;

    constructor(choiceGuid: string, isEnd?: boolean, questionGuid?: string, guid?: string) {
        this.questionChoiceGuid = choiceGuid;

        if (questionGuid) {
            this.questionGuid = questionGuid;
            this.end = false;
        }

        if (guid) {
            this.guid = guid;
        }

        this.end = !!isEnd;
    }

    clone() {
        return new SurveyQuestionJump(this.questionChoiceGuid, this.end, this.questionGuid, this.guid);
    }

    serialize() {
        const toReturn: any = {};

        toReturn.choiceGuid = this.questionChoiceGuid;

        if (this.questionGuid) {
            toReturn.questionGuid = this.questionGuid;
        }

        if (!this.questionGuid && this.end) {
            toReturn.end = this.end;
        }

        if (this.questionGuid && this.end) {
            console.warn("Either a question guid is provided or end is provided. not both. please check object.");
        }

        return toReturn;

    }

}




import { Serializable } from '../new-model/serializable';
import { QualitySurveyQuestion } from './qualitySurveyQuestion';

export class QualitySurvey implements Serializable<QualitySurvey> {

  guid: string;

  maximumFailurePoints: number;

  qualitySurveyQuestions: QualitySurveyQuestion[];

  deserialize(input: any): QualitySurvey {
    return <QualitySurvey>{
      guid: input.guid,
      maximumFailurePoints: input.maximum_failure_points,
      qualitySurveyQuestions: input.quality_survey_questions ? input.quality_survey_questions.map(sq =>
        new QualitySurveyQuestion().deserialize(sq)
      ) : []
    };
  }

  serialize() {
    return {
      guid: this.guid,
      maximum_failure_points: this.maximumFailurePoints,
      qualitySurveyQuestions: this.qualitySurveyQuestions.map(sq =>
        sq.serialize()
      )
    };
  }
}

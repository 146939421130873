import { ContentProvider, Article } from "./content-provider";
import { Fixture } from "./fixture";
import { SurveyQuestion } from "../survey/surveyQuestion";
import { SurveyQuestionChoice } from "../survey/surveyQuestionChoice";

export class QualitySurveyAnswerException {
  public answer: SurveyAnswer;
  public answerData: AnswerData[];
  constructor(input: { answer: SurveyAnswer, answer_data: AnswerData[] }) {
    this.answer = new SurveyAnswer().deserialize(input.answer);
    this.answerData = input.answer_data.map(answer_data => new AnswerData().deserialize(answer_data));
  }
}

export class SurveyAnswer {
  public guid: string;
  public dataType: string;
  public timer: number;
  public surveyQuestion: SurveyQuestion;
  public contentProvider?: ContentProvider;
  public fixture?: Fixture;

  deserialize(input): SurveyAnswer {
    this.guid = input.guid;
    this.dataType = input.data_type;
    this.timer = input.timer;
    this.surveyQuestion = new SurveyQuestion().deserialize(input.survey_question);
    this.contentProvider = input.content_provider ? new ContentProvider().deserialize(input.content_provider) : null;
    this.fixture = input.fixture ? new Fixture().deserialize(input.fixture) : null;
    return this;
  }
}

export class AnswerData {
  public guid: string;
  public surveyQuestionChoice?: SurveyQuestionChoice;
  public value?: string;
  public photo?: string;
  public facings?: string;
  public cards?: string;
  public article?: Article;

  deserialize(input): AnswerData {
    this.guid = input.guid;
    this.surveyQuestionChoice = input.survey_question_choice ?
      new SurveyQuestionChoice().deserialize(input.survey_question_choice) : null;
    this.value = input.value ? input.value : '';
    this.photo = input.photo ? input.photo : '';
    this.facings = input.facings ? input.facings : '';
    this.cards = input.cards ? input.cards : '';
    this.article = input.article ? new Article().deserialize(input.article) : null;
    return this;
  }
}

export class QualitySurveyPlanogramException {
  public answerData: AnswerData[];
  constructor(input: { answer: SurveyAnswer, answer_data: AnswerData[] }) {

    this.answerData = input.answer_data.map(answer_data => new AnswerData().deserialize(answer_data));
  }
}
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ExportVisitsService } from '../../../services/exportVisits.service';
import { VisitStates } from '../../../models/projectVisitsStates';
import { Project } from '../../../models/new-model/project';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'export-project-visits-old',
  templateUrl: './export-project-visits-old.component.html',
  styleUrls: ['./export-project-visits-old.component.scss']
})
export class ExportProjectVisitsOldComponent implements OnInit {

  states;
  editingMode: boolean;
  project: Project;
  exportForm: FormGroup;
  successDownload: boolean;
  failDownloadMessage: string;
  disabled = true;

  constructor(public dialogRef: MatDialogRef<ExportProjectVisitsOldComponent>, public exportService: ExportVisitsService, private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { project: Project, editingMode: boolean }, ) {

    this.states = VisitStates;
    this.editingMode = data.editingMode;
    this.project = data.project;
    this.exportForm = this.createForm();
    this.exportForm.setValue({ 'status': '' });
  }

  ngOnInit() {
  }

  export(e) {
    //export csv file
    this.exportService.downloadFileOld(this.project, this.exportForm.value.status.status).subscribe(res => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    }, error => {
      this.successDownload = false;
      this.failDownloadMessage = JSON.stringify(error);
      console.log('download error:', JSON.stringify(error));
    }, () => {
      this.successDownload = true;
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  createForm() {
    return this.fb.group({
      'status': this.fb.control('')
    });
  }

  unsetDisable(e) {
    //enable export button
    this.disabled = false;
  }


}

import { UserDetails, User, Role } from "./../../../../models/new-model/user";
import { UserService } from "./../../../../services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { PltCardComponent } from "./../../../../plt-shared/plt-card/plt-card.component";
import { PltSingleContactPersonComponent } from "./../../../../plt-shared/plt-single-contact-person/plt-single-contact-person.component";
import { PltCardBodyComponent } from "./../../../../plt-shared/plt-card-body/plt-card-body.component";
import { AfterViewInit, ViewChild } from "@angular/core";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  AbstractControl
} from "@angular/forms";

@Component({
  selector: "create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent implements OnInit, AfterViewInit {
  contactForm: PltSingleContactPersonComponent;
  userGuid: string;
  editMode = false;
  userDetails$: Observable<UserDetails>;
  rolesForm: FormGroup;
  roles$: Observable<Role[]>;
  CFCopy: any;
  URCopy: any;
  userDetails: UserDetails;

  @ViewChild(PltCardComponent)
  contactCard: PltCardComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.userGuid = this.activeRoute.snapshot.params.guid;
    if (!this.userGuid) {
      this.editMode = !this.editMode;
    }
    this.contactForm = <PltSingleContactPersonComponent>(
      (<PltCardBodyComponent>this.contactCard.body).form
    );
  }

  ngAfterViewInit() {
    setTimeout(_ => {
      if (this.userGuid) {
        this.userService
          .getUserDetails(this.userGuid)
          .subscribe((result: UserDetails) => {
            this.userDetails = result;
            this.contactForm.initForm(result.contactPerson);
            this.contactForm.disableForm();
            this.roles$ = this.userService.getAvailableUserRoles(this.userGuid);
            this.roles$.subscribe(() => {
              this.resetRoles(this.userDetails.roles, this.userDetails.account.ba_ds_id);
            })
          });

      } else {
        this.roles$ = this.userService.getAvailableUserRoles(this.userGuid);
      }
    });
  }

  edit() {
    this.editMode = !this.editMode;
    this.CFCopy = Object.assign(
      {},
      this.contactForm.contactPersonForm.getRawValue()
    );

    this.URCopy = Object.assign({}, this.rolesForm.getRawValue());
    this.contactForm.contactPersonForm.enable();
    this.rolesForm.enable();
    this.rolesForm.get('ba_ds_id').disable();
  }

  save() {
    const userInfo = this.contactForm.getContactPerson().serialize();
    let user = new User().deserialize(userInfo);
    user.email =
      userInfo.communication_details[0].type === "Mail_address"
        ? userInfo.communication_details[0].value
        : "";

    const roles = this.rolesForm.getRawValue().userRoles;
    // existing user
    if (this.editMode && this.userGuid) {
      this.userService
        .editUser(user, this.userGuid)
        .subscribe((user: UserDetails) => {
          this.contactForm.contactPersonForm.disable();
        });
      this.userService
        .editUserRoles(roles, this.userGuid)
        .subscribe((user: UserDetails) => {
          this.rolesForm.reset({ userRoles: user.roles, ba_ds_id: user.account.ba_ds_id });
          this.rolesForm.disable();
        });
      this.editMode = !this.editMode;
    } else {
      // new user
      user.roles = roles;
      this.userService.createUser(user).subscribe((user: UserDetails) => {
        this.editMode = !this.editMode;
        this.contactForm.contactPersonForm.disable();
        this.rolesForm.disable();
        this.router.navigate([`../view-user/${user.account.guid}`], {
          relativeTo: this.activeRoute
        });
      });
    }
  }

  cancel() {
    if (this.userGuid) {
      this.contactForm.contactPersonForm.reset(this.CFCopy);
      this.contactForm.contactPersonForm.disable();
      this.rolesForm.reset(this.URCopy);
      this.rolesForm.disable();
      this.editMode = !this.editMode;
    } else {
      this.router.navigate(["../"], { relativeTo: this.activeRoute });
    }
  }

  isFormValid(): boolean {
    return this.contactForm.contactPersonForm.valid;
  }

  private createForm() {
    this.rolesForm = this.fb.group({
      userRoles: this.fb.array([new Role()]),
      ba_ds_id: this.fb.control({ value: '', disabled: true })
    });
  }

  getFormArray(name): FormArray {
    return this.rolesForm.get(name) as FormArray;
  }

  getFormControl(idx) {
    return this.getFormArray('userRoles').controls[idx]
  }

  getConsoleLog(role) {
    console.log(role)
  }

  addField(f?: Role) {
    const field = f ? f : new Role();
    this.getFormArray("userRoles").push(
      this.fb.control(field, ValidateUserRoles)
    );
  }

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  getAlreadySelected(role: FormGroup, formKey: string) {
    return this.getFormArray(formKey)
      .controls.filter(
        item => role.value.identifier !== item.value.identifier
      ).map((r) => r.value.identifier)
  }

  removeField(idx) {
    this.getFormArray("userRoles").removeAt(idx);
  }

  isEmpty(idx) {
    return this.getFormArray("userRoles").controls[idx].value.identifier === "";
  }

  removeFieldContent(idx) {
    this.getFormArray("userRoles").controls[idx].setValue(new Role());
  }

  resetRoles(roles, ba_ds_id) {
    this.rolesForm.patchValue({
      userRoles: [],
      ba_ds_id: ba_ds_id
    });

    if (roles && roles.length > 0) {
      this.getFormArray("userRoles").removeAt(0);
      roles.forEach(r => {
        this.addField(r);
      });
    }
    this.rolesForm.disable();
  }
}

export function ValidateUserRoles(control: AbstractControl) {
  return !control.value.identifier
    ? { emptyUR: { value: control.value } }
    : null;
}

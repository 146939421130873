import { Serializable } from "./serializable";
import { ContentProvider } from "./content-provider";


export class TagStub implements Serializable<TagStub> {
  name: string;
  guid: string;
  provider: ContentProvider;

  constructor() { }

  deserialize(input) {
    this.name = input.name;
    this.guid = input.guid;
    this.provider = input.provider;
    return this;
  }

  serialize() {
    return {
      name: this.name,
      guid: this.guid,
      provider: this.provider
    };
  }
}

export class TagProvider implements Serializable<TagProvider> {
  name: string;
  guid: string;
  contentProviderGuid: String;

  constructor() { }

  deserialize(input) {
    this.name = input.name;
    this.guid = input.guid;
    this.contentProviderGuid = input.content_provider;
    return this;
  }

  serialize() {
    return {
      name: this.name,
      guid: this.guid,
      content_provider: this.contentProviderGuid
    };
  }
}
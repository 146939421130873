export const roleMapping = {
  'draft': {
    'master': {
      'basicprojectdata': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'si': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'cp': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'visitsurveys': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'qualitycontrol': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': true,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'projecttesting': {
        'create': false,
        'add': false,
        'edit': true,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      }
    },
    'admin': {
      'basicprojectdata': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'si': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'cp': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'visitsurveys': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'qualitycontrol': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': true,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'projecttesting': {
        'create': false,
        'add': false,
        'edit': true,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      }
    },
    'projectleader': {
      'basicprojectdata': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'si': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'cp': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'visitsurveys': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'qualitycontrol': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'projecttesting': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      }
    },
    'projectmanager': {
      'basicprojectdata': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'si': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'cp': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': false,
        'import': true,
        'export': true
      },
      'visitsurveys': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'qualitycontrol': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'projecttesting': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      }
    },
    'fieldmanager': {
      'basicprojectdata': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'projecttesting': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      }
    },
    'qualitymanager': {
      'basicprojectdata': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projecttesting': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      }
    },
    'qualityagent': {
      'basicprojectdata': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projecttesting': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      }
    },
    'merchandiser': {
      'basicprojectdata': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projecttesting': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      }
    }
  },
  'running': {
    'master': {
      'basicprojectdata': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'si': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'cp': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'visitsurveys': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'qualitycontrol': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'checkerscreen': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': true,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'overview': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': true
      }
    },
    'admin': {
      'basicprojectdata': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'si': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'cp': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'visitsurveys': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'qualitycontrol': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': true,
        'export': true
      },
      'checkerscreen': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': true,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'overview': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': true
      }
    },
    'projectleader': {
      'basicprojectdata': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': true,
        'export': true
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': false,
        'import': true,
        'export': true
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'checkerscreen': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'overview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': true
      }
    },
    'projectmanager': {
      'basicprojectdata': {
        'create': true,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': true,
        'export': true
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': false,
        'import': true,
        'export': true
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'checkerscreen': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'overview': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': true,
        'import': false,
        'export': true
      }
    },
    'fieldmanager': {
      'basicprojectdata': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': true,
        'add': true,
        'edit': true,
        'read': true,
        'delete': false,
        'import': true,
        'export': true
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'checkerscreen': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'overview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': true
      }
    },
    'qualitymanager': {
      'basicprojectdata': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'checkerscreen': {
        'create': false,
        'add': false,
        'edit': true,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'overview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': true
      }
    },
    'qualityagent': {
      'basicprojectdata': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'checkerscreen': {
        'create': false,
        'add': false,
        'edit': true,
        'read': true,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'overview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': true,
        'delete': false,
        'import': false,
        'export': true
      }
    },
    'merchandiser': {
      'basicprojectdata': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'retailer': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'si': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'cp': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'fixtures': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitsurveys': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'visitplanning': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'qualitycontrol': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'checkerscreen': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'adminarea': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'projectreview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      },
      'overview': {
        'create': false,
        'add': false,
        'edit': false,
        'read': false,
        'delete': false,
        'import': false,
        'export': false
      }
    }
  }
};

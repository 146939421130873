import { VisitPlanning } from './../visit-planning/visitPlanning';
import { QualitySurvey } from './../quality-survey/qualitySurvey';
import { Survey } from './../survey/survey';
import { Fixture, FixtureArticle } from './fixture';
import { ContentProvider } from './content-provider';
import { SystemIntegrator } from './system-integrator';
import { Serializable } from "./serializable";
import { Project, ProjectAccountDetails } from "./project";
import { OrderRecipient } from './order';

export class ProjectDetails implements Serializable<ProjectDetails> {

  project: Project;
  accounts: Map<string, ProjectAccountDetails[]>;
  systemIntegrators: SystemIntegrator[];
  contentProviders: ContentProvider[];
  fixtures: Fixture[];
  articles: FixtureArticle[];
  surveys: Survey[];
  qualitySurveys: QualitySurvey[];
  orderRecipients: OrderRecipient[];
  visitPlannings: VisitPlanning[];


  deserialize(input: any) {
    this.project = new Project().deserialize(input.project);
    this.accounts = new Map<string, ProjectAccountDetails[]>();
    for (var prop in input.project_accounts) {
      if (input.project_accounts.hasOwnProperty(prop)) {
          this.accounts.set(prop, input.project_accounts[prop].map(json => new ProjectAccountDetails().deserialize(json)));
      }
  }
    this.systemIntegrators = input.system_integrators.length ? input.system_integrators.map((si: SystemIntegrator) => new SystemIntegrator().deserialize(si)) : [];
    this.contentProviders = input.content_providers.length ? input.content_providers.map((cp: ContentProvider) => new ContentProvider().deserialize(cp)) : [];
    this.fixtures = input.fixtures.length ? input.fixtures.map((fixt: Fixture) => new Fixture().deserialize(fixt)) : [];
    this.articles = input.articles.length ? input.articles.map((art: FixtureArticle) => new FixtureArticle().deserialize(art)) : [];
    this.surveys = input.surveys.length ? input.surveys.map((survey: Survey) => new Survey().deserialize(survey)) : [];
    this.qualitySurveys = input.quality_surveys.length ? input.quality_surveys.map((qs: QualitySurvey) => new QualitySurvey().deserialize(qs)) : [];
    this.orderRecipients = input.order_recipients.length ? input.order_recipients.map((or: OrderRecipient) => new OrderRecipient().deserialize(or)) : [];
    this.visitPlannings = input.visit_plannings.length ? input.visit_plannings.map((vp: VisitPlanning) => new VisitPlanning().deserialize(vp)) : [];
    return this;
  }

  serialize() {
    return {

    }
  }
}

import { Serializable } from "../new-model/serializable";

export class VisitPlanningPost implements Serializable<VisitPlanningPost> {

    teamLeaderGuid: string;

    merchandiserGuid: string;

    deserialize(input: any): VisitPlanningPost {
        return <VisitPlanningPost>{
            merchandiserGuid: input.merchandiser_guid,
            teamLeaderGuid: input.teamleader_guid
        };
    }

    serialize() {
        return {
            teamleader_guid: this.teamLeaderGuid,
            merchandiser_guid: this.merchandiserGuid
        };
    }
}

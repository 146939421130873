//SIDE MENU

export const MENU = {
    home: [
        {
            "title": "Overview",
            "icon": "fa fa-paper-plane",
            "state": 0,
            "route": "/overviews"
        },
        {
            "title": "Draft projects",
            "icon": "fa fa-adjust",
            "state": 0,
            "route": "/drafts"
        }
    ],
    draft: [
        {
            "title": "Basic project data",
            "icon": "fas fa-clipboard",
            "state": 0,
            "route": { root: "/draft", subRoot: "basic-data" },
            "dbname": "basic_data",
        },
        {
            "title": "Business partners",
            "icon": "fas fa-puzzle-piece",
            "state": 0,
            "route": { root: "/draft", subRoot: "partners" },
            "dbname": "partners",
        },
        {
            "title": "Fixtures",
            "icon": "fas fa-table",
            "state": 0,
            "route": { root: "/draft", subRoot: "fixtures" },
            "dbname": "fixtures",
        },
        {
            "title": "Visit surveys",
            "icon": "fas fa-tasks",
            "state": 0,
            "route": { root: "/draft", subRoot: "visit_surveys" },
            "dbname": "visit_surveys",
        },
        {
            "title": "Visit planning",
            "icon": "fas fa-users",
            "state": 0,
            "route": { root: "/draft", subRoot: "visit_planning" },
            "dbname": "visit_planning",
        },
        {
            "title": "Quality surveys",
            "icon": "fas fa-certificate",
            "state": 0,
            "route": { root: "/draft", subRoot: "quality_surveys" },
            "dbname": "quality_surveys",
        },
        {
            "title": "Project summary",
            "icon": "fas fa-diagnoses",
            "state": 0,
            "route": { root: "/draft", subRoot: "project_summary" },
            "dbname": "summary",
        }
    ],
    draftNew: [
        {
            "title": "Overview",
            "icon": "fas fa-clipboard",
            "state": 2,
            "route": "/draft/new-project"
        }
    ],
    running: [
        {
            "title": "Basic project data",
            "icon": "fas fa-clipboard",
            "state": 0,
            "route": { root: "/running", subRoot: "basic-data" },
            "dbname": "basic_data",
        },
        {
            "title": "Business partners",
            "icon": "fas fa-puzzle-piece",
            "state": 0,
            "route": { root: "/running", subRoot: "partners" },
            "dbname": "partners",
        },
        {
            "title": "Fixtures",
            "icon": "fas fa-table",
            "state": 0,
            "route": { root: "/running", subRoot: "fixtures" },
            "dbname": "fixtures",
        },
        {
            "title": "Visit surveys",
            "icon": "fas fa-tasks",
            "state": 0,
            "route": { root: "/running", subRoot: "visit_surveys" },
            "dbname": "visit_surveys",
        },
        {
            "title": "Visit planning",
            "icon": "fas fa-users",
            "state": 0,
            "route": { root: "/running", subRoot: "visit_planning" },
            "dbname": "visit_planning",
        },
        {
            "title": "Quality surveys",
            "icon": "fas fa-certificate",
            "state": 0,
            "route": { root: "/running", subRoot: "quality_surveys" },
            "dbname": "quality_surveys",
        }
    ]
}

export class ProjectMenu {
    menu: Array<any>;

    constructor(type: string, excludeMenuItems?: Array<string>) {
        if (!excludeMenuItems) {
            excludeMenuItems = [];
        }

        this.menu = MENU[type].filter((item) => {
            return !excludeMenuItems.includes(item.dbname);
        });
    }

    addProgress(progress) {
        this.menu.forEach((menuItem, index) => {
            if (progress[menuItem.dbname]) {
                this.menu[index].state = progress[menuItem.dbname].status;
            }
        });

        return this;
    }

}

export class PartnerSubMenu {
    retailer: PartnerSubMenuItem;
    systemIntegrators: PartnerSubMenuItem;
    contentProviders: PartnerSubMenuItem;
    menu: PartnerSubMenuItem[];

    constructor() {
        this.retailer = new PartnerSubMenuItem({
            title: "Retailer",
            route: "retailers",
            dbName: "retailer"
        });
        this.systemIntegrators = new PartnerSubMenuItem({
            title: "System integrator(s)",
            route: "system-integrators",
            dbName: "system_integrators"
        });
        this.contentProviders = new PartnerSubMenuItem({
            title: "Content provider(s)",
            route: "content-providers",
            dbName: "content_providers"
        });

        this.menu = [this.retailer, this.systemIntegrators, this.contentProviders];
    }
}

export class PartnerSubMenuItem {
    title: string;
    state: number;
    selected: boolean;
    route: string;
    dbName: string;

    constructor(item) {
        this.title = item.title || '';
        this.state = item.state || 0;
        this.selected = item.selected || false;
        this.route = item.route || '';
        this.dbName = item.dbName || '';
    }
}

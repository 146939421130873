import { Serializable } from "./serializable";

export class OrderRecipient implements Serializable<OrderRecipient>{
  projectsId: number;
  projectOrderRecipientTypesId: number;
  guid: string;
  mailAddress: string;
  projectRecipientTypes: RecipientType;

    constructor(mailAddress?: string, type?: object ) {
        this.mailAddress = mailAddress;
        this.projectRecipientTypes = new RecipientType().deserialize(type || {});
    }

    deserialize(input) {
        this.projectsId = input.projects_id;
        this.projectOrderRecipientTypesId = input.project_order_recipient_types_id;
        this.guid = input.guid;
        this.mailAddress = input.mail_address;
        this.projectRecipientTypes = new RecipientType().deserialize(input.project_recipient_types || {});
        return this;
    }

    serialize() {
        return {
            projects_id: this.projectsId,
            project_order_recipient_types_id: this.projectOrderRecipientTypesId,
            guid: this.guid,
            mail_address: this.mailAddress,
            project_recipient_types: this.projectRecipientTypes.serialize(),
        }
    }

    get email(): string {
      return this.mailAddress;
    }
    get slug(): string {
      return this.projectRecipientTypes.slug;
    }

}


export class RecipientType implements Serializable<RecipientType> {
  name: string;
  slug: string;
  constructor(name?: string, slug?: string) {
    this.name = name;
    this.slug = slug;
  }
  deserialize(input) {
      this.name = input.name;
      this.slug = input.slug;
      return this;
  }

  serialize() {
      return {
          name: this.name,
          slug: this.slug,
      }
  }
}


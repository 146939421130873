import { Serializable } from "./serializable";
import { ProjectDate } from "./project";
import { Merchandiser } from "../visit-planning/merchandiser";
import { Store } from "../visit-planning/store";
import { Type } from "./type";


export class StoreVisit {

    guid: string;
    start: ProjectDate;
    end: ProjectDate;
    merchandiser: Merchandiser;
    store: Store;
    visitStatus: VisitStatus;
    closed: boolean;

    constructor(element: any) {
        this.guid = element.guid || '';
        this.end = element.end || '';
        this.start = element.start || '';
        this.merchandiser = element.merchandiser ? new Merchandiser().deserialize(element.merchandiser) : new Merchandiser().deserialize({});
        this.store = element.store ? new Store().deserialize(element.store) : new Store().deserialize({});
        this.visitStatus = new VisitStatus(element.visit_status);
        this.closed = element.closed;
    }

    getDateString(type) {
        if (this[type]) {
            return new Date(this.start.date).toLocaleDateString('de')
        }

        return "Not Visited";
    }

}

export class VisitStatus extends Type {
    slug: string;

    constructor(element) {
        if (element) {
            super(element.id, element.name);
            this.slug = element.slug;
        }
        else {
            super(-1, 'Not Started');
            this.slug = 'not-started';
        }
    }

    isVisitOpen(): boolean {
        return this.id <= 4;
    }

    isVisitAborted(): boolean {
        return this.id === 5;
    }

    isVisitChecked(): boolean {
        return this.id === 7 || this.id === 6;
    }

    isVisitCheckRejected(): boolean {
        return this.id === 8;
    }

    isVisitDone(): boolean {
        return this.id === 9;
    }

    getVisitStatusDisplay(type: string) {
        switch (type) {
            case 'exception': return {
                name: 'Store Exception',
                value: 'fas fa-times-circle'
            }
            case 'open': return {
                name: 'Open',
                value: 'fas fa-box-open'
            }
            case 'checked': return {
                name: 'Done',
                value: 'fas fa-check-circle'
            }
            case 'not-checked': return {
                name: 'Not Checked',
                value: 'fas fa-box'
            }
            case 'rejected': return {
                name: 'Rejected',
                value: 'fas fa-redo'
            }
        }
    }

    static getAllStatuses() {
        return [
            {
                "name": "All stores",        
                "status": "ALL",
                "visit":"all"
            },
            {
                "name": "Stores with status OK",        
                "status": "OK",
                "visit":"ok"
            },
            {
                "name": "Stores with status NOK",        
                "status": "NOT_OK",
                "visit":"not_ok"
            },
            {
                "name": "Stores with status Not checked",        
                "status": "NOT_CHECKED",
                "visit":"not_checked"
            },
            {
                "name": "Stores with status Open",        
                "status": "OPEN",
                "visit":"open"
            }
        ]
    }


}
import { StoreDataDetailsComponent } from "./plentycore/steps/step2/retailers/store-data-details/store-data-details.component";
import { FixturesRootComponent } from "./plentycore/steps/step3/fixtures-root/fixtures-root.component";
import { ContentProviderRootComponent } from "./plentycore/steps/step2/content-providers/content-provider-root/content-provider-root.component";
import { RetailerRootComponent } from "./plentycore/steps/step2/retailers/retailer-root/retailer-root.component";

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CheckersScreenComponent } from "./plentycore/checkers-screen/checkers-screen.component";
import { CreateProjectComponent } from "./plentycore/steps/step1/create-project/create-project.component";
import { DraftComponent } from "./plentycore/draft/draft.component";
import { DraftsComponent } from "./plentycore/drafts/drafts.component";
import { HomeComponent } from "./plentycore/home/home.component";
import { LoginChangePasswordComponent } from "./plentycore/login/login-change-password/login-change-password.component";
import { LoginResetPasswordComponent } from "./plentycore/login/login-reset-password/login-reset-password.component";
import { LoginComponent } from "./plentycore/login/login.component";
import { LogoutComponent } from "./plentycore/logout/logout.component";
import { NewProjectComponent } from "./plentycore/steps/step1/new-project/new-project.component";
import { ProjectsOverviewComponent } from "./plentycore/projects-overview/projects-overview.component";
import {
  CreateContentProviderComponent,
  SelectContentProvider
} from "./plentycore/steps/step2/content-providers";
import { ContentProviderEditArticleComponent } from "./plentycore/steps/step2/content-providers/content-provider-edit-article/content-provider-edit-article.component";
import { ContentProviderEditContactComponent } from "./plentycore/steps/step2/content-providers/content-provider-edit-contact/content-provider-edit-contact.component";
import { CreateNewArticleComponent } from "./plentycore/steps/step2/content-providers/create-new-article/create-new-article.component";
import { CreateNewContactComponent } from "./plentycore/steps/step2/content-providers/create-new-contact/create-new-contact.component";
import { DetailsContentProvider } from "./plentycore/steps/step2/content-providers/details-content-providers/details-content-provider.component";
import { ListContentProvider } from "./plentycore/steps/step2/content-providers/list-content-provider/list-content-provider.component";
import { PartnersComponent } from "./plentycore/steps/step2/partners/partners.component";
import { AddNewRetailerComponent } from "./plentycore/steps/step2/retailers/add-new-retailer/add-new-retailer.component";
import { CreateRetailerContactComponent } from "./plentycore/steps/step2/retailers/create-retailer-contact/create-retailer-contact.component";
import { EditRetailerContactComponent } from "./plentycore/steps/step2/retailers/edit-retailer-contact/edit-retailer-contact.component";
import { ExistingRetailersComponent } from "./plentycore/steps/step2/retailers/existing-retailers/existing-retailers.component";
import { RetailerOverviewComponent } from "./plentycore/steps/step2/retailers/retailer-overview/retailer-overview.component";
import {
  EditSystemIntegrator,
  NewSystemIntegrator,
  SelectSystemIntegrator
} from "./plentycore/steps/step2/system-integrators";
import { ContactPersonSystemIntegratorComponent } from "./plentycore/steps/step2/system-integrators/contact-person-system-integrator/contact-person-system-integrator.component";
import { ListSystemIntegratorsComponent } from "./plentycore/steps/step2/system-integrators/list-system-integrators/list-system-integrators.component";
import { SystemIntegratorEditContactComponent } from "./plentycore/steps/step2/system-integrators/system-integrator-edit-contact/system-integrator-edit-contact.component";

import { CreateFixtureComponent } from "./plentycore/steps/step3/create-fixture/create-fixture.component";
import { DetailsFixtureArticleComponent } from "./plentycore/steps/step3/details-fixture/details-fixture-article/details-fixture-article.component";
import { DetailsFixtureComponent } from "./plentycore/steps/step3/details-fixture/details-fixture.component";
import { EditFixtureComponent } from "./plentycore/steps/step3/edit-fixture/edit-fixture.component";
import { ExistingFixturesComponent } from "./plentycore/steps/step3/existing-fixtures/existing-fixtures.component";
import { SelectedFixturesListComponent } from "./plentycore/steps/step3/selected-fixtures-list/selected-fixtures-list.component";

import { SurveyComponent } from "./plentycore/steps/step4/survey/survey.component";
import {
  ExistingVisitPlanningComponent,
  ImportVisitPlanningComponent
} from "./plentycore/steps/step5";
import {
  QualitySurveyOverviewComponent,
  QualitySurveyOverviewEmptyComponent
} from "./plentycore/steps/step6";
import { ProjectSummaryComponent } from "./plentycore/steps/step7/project-summary/project-summary.component";
import { PltImportCompareComponent } from "./plt-shared/plt-import-compare/plt-import-compare.component";
import { AuthGuard } from "./services/auth.guard";
import { RoleGuard } from "./services/role.guard";
import { RetailerSelectedGuard } from "./services/redirects/retailerRedirect.guard";
import * as RESOLVERS from "./services/resolvers/resolvers";
import { SystemIntegratorRootComponent } from "./plentycore/steps/step2/system-integrators/system-integrator-root/system-integrator-root.component";
import { AdminComponent } from "./plentycore/admin/admin.component";
import { UserManagementComponent } from "./plentycore/admin/user-management/user-management.component";
import { LogComponent } from "./plentycore/admin/log/log.component";
import { MerchandiserComponent } from "./plentycore/admin/log/merchandiser/merchandiser.component";
import { StoreComponent } from "./plentycore/admin/log/store/store.component";
import { CreateUserComponent } from "./plentycore/admin/user-management/create-user/create-user.component";
import { SurveyQuestionPageComponent } from "./plentycore/steps/step4/survey-question-page/survey-question-page.component";

const RUNNING_PROJECT_DATA = {
  isRunning: true,
  projectType: "running"
};

const DRAFT_PROJECT_DATA = {
  isRunning: false,
  projectType: "draft"
};

const routes: Routes = [
  {
    path: "",
    redirectTo: "overviews",
    pathMatch: "full"
  },
  {
    path: "changePassword/:token",
    component: LoginChangePasswordComponent
  },
  {
    path: "changePassword",
    component: LoginChangePasswordComponent
  },
  {
    path: "resetPassword",
    component: LoginResetPasswordComponent
  },
  {
    path: "import-compare",
    component: PltImportCompareComponent,
    canActivate: [AuthGuard]
  },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "admin",
        component: AdminComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          authorizedRoles: ["master", "admin"]
        },
        children: [
          {
            path: "",
            redirectTo: "user-management",
            canActivate: [AuthGuard],
            pathMatch: "full"
          },
          {
            path: "user-management",
            children: [
              {
                path: "",
                component: UserManagementComponent,
                canActivate: [AuthGuard]
              },
              {
                path: "create-user",
                component: CreateUserComponent,
                canActivate: [AuthGuard]
              },
              {
                path: "view-user/:guid",
                component: CreateUserComponent,
                canActivate: [AuthGuard]
              }
            ]
          },
          {
            path: "logs",
            component: LogComponent,
            children: [
              {
                path: "",
                redirectTo: "merchandiser",
                pathMatch: "full"
              },
              {
                path: "merchandiser",
                component: MerchandiserComponent,
                canActivate: [AuthGuard]
              },
              {
                path: "store",
                component: StoreComponent,
                canActivate: [AuthGuard]
              }
            ]
          }
        ]
      },
      {
        path: "overviews",
        component: ProjectsOverviewComponent,
        canActivate: [AuthGuard],
        data: {
          path: 'running'
        }
      },
      {
        path: "drafts",
        component: DraftsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          path: 'draft',
          authorizedRoles: ['master', 'admin', 'projectleader', 'projectmanager', 'fieldmanager', 'qualitymanager', 'qualityagent']
        }
      },
      {
        path: "draft",
        component: DraftComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          path: 'draft',
          authorizedRoles: ['master', 'admin', 'projectleader', 'projectmanager', 'fieldmanager']
        },
        children: [
          {
            path: "",
            redirectTo: "new-project",
            pathMatch: "full"
          },
          {
            path: "new-project",
            component: CreateProjectComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: {
              authorizedRoles: ['master', 'admin', 'projectleader', 'projectmanager']
            }
          },
          {
            path: ":guid",
            canActivate: [AuthGuard],
            resolve: {
              projectGuid: RESOLVERS.ProjectGuidResolver,
              projectDetails: RESOLVERS.ProjectDetailstResolver
            },
            data: {
              path: 'draft'
            },
            children: [
              {
                path: "basic-data",
                component: NewProjectComponent,
                canActivate: [AuthGuard],
              },
              {
                path: "partners",
                component: PartnersComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: "",
                    redirectTo: "retailers",
                    pathMatch: "full"
                  },
                  {
                    path: "retailers",
                    canActivate: [AuthGuard],
                    data: {
                      path: 'draft'
                    },
                    children: [
                      {
                        path: "",
                        component: RetailerRootComponent,
                        resolve: {
                          retailerForProject:
                            RESOLVERS.RetailerForProjectResolver
                        }
                      },
                      {
                        path: "select/:view",
                        component: ExistingRetailersComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                          retailerList: RESOLVERS.RetailerListResolver,
                          projectDetails: RESOLVERS.ProjectDetailstResolver,
                          retailerForProject:
                            RESOLVERS.RetailerForProjectResolver
                        }
                      },
                      {
                        path: "select",
                        component: ExistingRetailersComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                          retailerList: RESOLVERS.RetailerListResolver,
                          projectDetails: RESOLVERS.ProjectDetailstResolver,
                          retailerForProject:
                            RESOLVERS.RetailerForProjectResolver
                        }
                      },
                      {
                        path: "new",
                        component: AddNewRetailerComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: "edit/:guid",
                        component: RetailerOverviewComponent,
                        canActivate: [AuthGuard, RetailerSelectedGuard],
                        resolve: {
                          basicData: RESOLVERS.RetailBasicDataResolver
                        }
                      },
                      {
                        path: "edit/:guid/add-contact",
                        component: CreateRetailerContactComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: "edit/:guid/edit-contact/:contactGuid",
                        component: EditRetailerContactComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: "view-store/:storeGuid",
                        component: StoreDataDetailsComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: "import-compare",
                        component: PltImportCompareComponent,
                        canActivate: [AuthGuard]
                      }
                    ]
                  },
                  {
                    path: "system-integrators",
                    canActivate: [AuthGuard],
                    data: {
                      path: 'draft'
                    },
                    children: [
                      {
                        path: "",
                        component: SystemIntegratorRootComponent,
                        resolve: {
                          selectedIntegratorList:
                            RESOLVERS.SystemIntegratorForProjectResolver
                        }
                      },
                      {
                        path: "select",
                        canActivate: [AuthGuard],
                        component: SelectSystemIntegrator,
                        resolve: {
                          integratorList: RESOLVERS.SystemIntegratorsResolver,
                          selectedIntegratorList:
                            RESOLVERS.SystemIntegratorForProjectResolver
                        }
                      },
                      {
                        path: "new",
                        canActivate: [AuthGuard],
                        component: NewSystemIntegrator
                      },
                      {
                        path: "details/:siGuid",
                        canActivate: [AuthGuard],
                        component: EditSystemIntegrator
                      },
                      {
                        path: "details/:siGuid/add-contact",
                        canActivate: [AuthGuard],
                        component: ContactPersonSystemIntegratorComponent
                      },
                      {
                        path: "viewlist",
                        canActivate: [AuthGuard],
                        component: ListSystemIntegratorsComponent
                      },
                      {
                        path: "details/:siGuid/edit-contact/:guid",
                        canActivate: [AuthGuard],
                        component: SystemIntegratorEditContactComponent
                      }
                    ]
                  },
                  {
                    path: "content-providers",
                    canActivate: [AuthGuard],
                    data: {
                      path: 'draft'
                    },
                    children: [
                      {
                        path: "",
                        component: ContentProviderRootComponent,
                        resolve: {
                          projectContentProviderList:
                            RESOLVERS.ContentProviderForProjectResolver
                        }
                      },
                      {
                        path: "select",
                        canActivate: [AuthGuard],
                        component: SelectContentProvider,
                        resolve: {
                          projectContentProviderList:
                            RESOLVERS.ContentProviderForProjectResolver
                        }
                      },
                      {
                        path: "create",
                        canActivate: [AuthGuard],
                        component: CreateContentProviderComponent
                      },
                      {
                        path: "viewlist",
                        canActivate: [AuthGuard],
                        component: ListContentProvider
                      },
                      {
                        path: "details/:guid",
                        canActivate: [AuthGuard],
                        component: DetailsContentProvider
                      },
                      {
                        path: "details/:guid/new-article",
                        canActivate: [AuthGuard],
                        component: CreateNewArticleComponent
                      },
                      {
                        path: "details/:guid/article/:guid",
                        canActivate: [AuthGuard],
                        component: ContentProviderEditArticleComponent
                      },
                      {
                        path: "details/:guid/new-contact",
                        canActivate: [AuthGuard],
                        component: CreateNewContactComponent
                      },
                      {
                        path: "details/:guid/edit-contact/:guid",
                        canActivate: [AuthGuard],
                        component: ContentProviderEditContactComponent
                      }
                    ]
                  }
                ]
              },
              {
                path: "fixtures",
                canActivate: [AuthGuard],
                children: [
                  {
                    path: "",
                    component: FixturesRootComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "select",
                    component: ExistingFixturesComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "new",
                    component: CreateFixtureComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "edit",
                    component: EditFixtureComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "details",
                    component: DetailsFixtureComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "details/:guid",
                    children: [
                      {
                        path: "",
                        component: DetailsFixtureComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: "article/:guid",
                        component: DetailsFixtureArticleComponent,
                        canActivate: [AuthGuard]
                      }
                    ]
                  },
                  {
                    path: "viewlist",
                    component: SelectedFixturesListComponent,
                    canActivate: [AuthGuard]
                  }
                ]
              },
              {
                path: "visit_surveys",
                canActivate: [AuthGuard],
                children: [
                  {
                    path: "",
                    component: SurveyComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "new_question/:category/:fixtureGuid",
                    component: SurveyQuestionPageComponent,
                    canActivate: [AuthGuard],
                    data: {
                      type: 'new'
                    }
                  },
                  {
                    path: "question/:guid",
                    component: SurveyQuestionPageComponent,
                    canActivate: [AuthGuard],
                    data: {
                      type: 'edit'
                    }
                  }
                ]
              },
              {
                path: "visit_planning",
                canActivate: [AuthGuard],
                children: [
                  {
                    path: "",
                    component: ExistingVisitPlanningComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "import",
                    component: ImportVisitPlanningComponent,
                    canActivate: [AuthGuard]
                  }
                ]
              },
              {
                path: "quality_surveys",
                canActivate: [AuthGuard],
                children: [
                  {
                    path: "",
                    component: QualitySurveyOverviewComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "start",
                    component: QualitySurveyOverviewEmptyComponent,
                    canActivate: [AuthGuard]
                  }
                ]
              },
              {
                path: "project_summary",
                canActivate: [AuthGuard],
                component: ProjectSummaryComponent
              }
            ]
          }
        ]
      },
      {
        path: "running",
        component: DraftComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          path: 'running',
          ...RUNNING_PROJECT_DATA,
          authorizedRoles: ['master', 'admin', 'projectleader', 'projectmanager', 'fieldmanager', 'qualitymanager', 'qualityagent']
        },
        children: [
          {
            path: "",
            redirectTo: "/overviews",
            pathMatch: "full"
          },
          {
            path: ":guid",
            canActivate: [AuthGuard],
            resolve: {
              projectGuid: RESOLVERS.ProjectGuidResolver,
              projectDetails: RESOLVERS.ProjectDetailstResolver
            },
            children: [
              {
                path: "basic-data",
                component: NewProjectComponent,
                canActivate: [AuthGuard],
                data: RUNNING_PROJECT_DATA
              },
              {
                path: "partners",
                component: PartnersComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: "",
                    redirectTo: "retailers",
                    pathMatch: "full"
                  },
                  {
                    path: "retailers",
                    canActivate: [AuthGuard],
                    data: {
                      path: 'running'
                    },
                    children: [
                      {
                        path: "",
                        redirectTo: "details",
                        pathMatch: "full"
                      },
                      {
                        path: "details",
                        component: RetailerOverviewComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: "view-store/:storeGuid",
                        component: StoreDataDetailsComponent,
                        canActivate: [AuthGuard],
                      },
                      {
                        path: "edit/:guid/edit-contact/:contactGuid",
                        component: EditRetailerContactComponent,
                        canActivate: [AuthGuard]
                      }
                    ]
                  },
                  {
                    path: "system-integrators",
                    canActivate: [AuthGuard],
                    data: {
                      path: 'running'
                    },
                    children: [
                      {
                        path: "",
                        canActivate: [AuthGuard],
                        component: ListSystemIntegratorsComponent
                      },
                      {
                        path: "select",
                        redirectTo: "",
                        pathMatch: "full"
                      },
                      {
                        path: "details/:siGuid",
                        canActivate: [AuthGuard],
                        component: EditSystemIntegrator
                      },
                      {
                        path: "details/:siGuid/edit-contact/:guid",
                        canActivate: [AuthGuard],
                        component: SystemIntegratorEditContactComponent
                      }
                    ]
                  },
                  {
                    path: "content-providers",
                    canActivate: [AuthGuard],
                    data: {
                      path: 'running'
                    },
                    children: [
                      {
                        path: "",
                        canActivate: [AuthGuard],
                        component: ListContentProvider
                      },
                      {
                        path: "select",
                        canActivate: [AuthGuard],
                        component: SelectContentProvider,
                        resolve: {
                          projectContentProviderList:
                            RESOLVERS.ContentProviderForProjectResolver
                        }
                      },
                      {
                        path: "viewlist",
                        canActivate: [AuthGuard],
                        component: ListContentProvider
                      },
                      {
                        path: "details/:guid",
                        canActivate: [AuthGuard],
                        component: DetailsContentProvider
                      },
                      {
                        path: "details/:guid/article/:guid",
                        canActivate: [AuthGuard],
                        component: ContentProviderEditArticleComponent
                      },
                      {
                        path: "details/:guid/edit-contact/:guid",
                        canActivate: [AuthGuard],
                        component: ContentProviderEditContactComponent
                      }
                    ]
                  }
                ]
              },
              {
                path: "fixtures",
                canActivate: [AuthGuard],
                children: [
                  {
                    path: "",
                    component: SelectedFixturesListComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "select",
                    component: ExistingFixturesComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: "details/:guid",
                    children: [
                      {
                        path: "",
                        component: DetailsFixtureComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: "article/:guid",
                        component: DetailsFixtureArticleComponent,
                        canActivate: [AuthGuard]
                      }
                    ]
                  }
                ]
              },
              {
                path: "visit_surveys",
                canActivate: [AuthGuard],
                children: [
                  {
                    path: "",
                    component: SurveyComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path:"new_question/:category/:fixtureGuid",
                    // path: "new_question/:category",
                    component: SurveyQuestionPageComponent,
                    canActivate: [AuthGuard],
                    data: {
                      type: 'new'
                    }
                  },
                  {
                    path: "question/:guid",
                    component: SurveyQuestionPageComponent,
                    canActivate: [AuthGuard],
                    data: {
                      type: 'edit'
                    }
                  }
                ]
              },
              {
                path: "visit_planning",
                canActivate: [AuthGuard],
                component: ExistingVisitPlanningComponent
              },
              {
                path: "quality_surveys",
                canActivate: [AuthGuard],
                children: [
                  {
                    path: "",
                    component: QualitySurveyOverviewComponent,
                    canActivate: [AuthGuard]
                  }
                ]
              },
              {
                path: "visit-checker/:guid",
                component: CheckersScreenComponent,
                canActivate: [AuthGuard],
                data: RUNNING_PROJECT_DATA
              }
            ]
          }
        ]
      }
    ]
  },

  { path: "**", redirectTo: "/overviews", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

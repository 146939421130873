import { Component, OnInit } from '@angular/core';
import { LogsService } from '../../../../services/logs.service';
import { MerchandiserLog, PackageLogsDataSource, PackageLog, PackageLogTableItem } from '../../../../models/new-model/log';

@Component({
  selector: 'app-merchandiser',
  templateUrl: './merchandiser.component.html',
  styleUrls: ['./merchandiser.component.scss']
})
export class MerchandiserComponent implements OnInit {
  public searchTerm = '';
  public merchandiserLog: MerchandiserLog;
  public dataSource: PackageLogsDataSource = new PackageLogsDataSource([]);
  public foundData = true;

  constructor(
    private logsService: LogsService
  ) { }

  ngOnInit() { }

  private updateDataSource(merchandiserLog: MerchandiserLog) {
    this.merchandiserLog = merchandiserLog;
    this.foundData = merchandiserLog.packages.length ? true : false;
    this.dataSource = new PackageLogsDataSource(
      merchandiserLog.packages.map(
        (packageLog: PackageLog) => new PackageLogTableItem(packageLog)
      )
    );
  }

  searchTermChanged(term: string) {
    this.searchTerm = term;
    this.getMerchandiserLogs();
  }

  getMerchandiserLogs() {
    if (this.searchTerm.length) {
      this.logsService.getMerchandiserLogsByName(this.searchTerm)
        .subscribe(merchandiserLog => this.updateDataSource(merchandiserLog));
    }
  }

}

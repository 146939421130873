import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'checker-gallery',
  templateUrl: './checker-gallery.component.html',
  styleUrls: ['./checker-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckerGalleryComponent implements OnInit {

  activePhoto: any;
  activePhotoIdx: number;
  lastPhoto: number;
  large = false;

  constructor(
    public dialogRef: MatDialogRef<CheckerGalleryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.activePhoto = this.data.photo;
    this.activePhotoIdx = this.data.allPhotos.findIndex(idx => idx.photo === this.activePhoto.photo);
    this.lastPhoto = this.data.allPhotos.length - 1;

  }

  ngOnInit() {

  }

  closeModal() {
    this.dialogRef.close();
  }

  clickedImage() {
    this.large = !this.large;
  }

  prevPhoto() {
    if (this.activePhotoIdx > 0) {
      this.activePhotoIdx--;
      this.activePhoto = this.data.allPhotos[this.activePhotoIdx];
    }

  }
  nextPhoto() {
    if (this.activePhotoIdx < this.data.allPhotos.length - 1) {
      this.activePhotoIdx++;
      this.activePhoto = this.data.allPhotos[this.activePhotoIdx];
    }



  }

}

import { Serializable } from "./serializable";
import { Address } from "./address";
import { ContactPerson } from "./contact-person";
import { Category } from "./category";
import { Type } from "./type";
import { SystemIntegrator } from "./system-integrator";
import { CardCountingMode } from "./fixture";

export class ContentProvider implements Serializable<ContentProvider> {
    guid: string;
    name: string;
    active: boolean;
    logo: string;
    address: Address;
    articles: Article[];
    contactPersons: ContactPerson[];
    systemIntegrators: SystemIntegrator[];


    deserialize(input) {
        this.guid = input.guid;
        this.name = input.name;
        this.active = input.active;
        this.logo = input.logo;
        this.address = new Address().deserialize(input.address || {});
        this.articles = input.articles ? input.articles.map(a => new Article().deserialize(a)) : [];
        this.contactPersons = input.contact_persons ? input.contact_persons.map(cp => new ContactPerson().deserialize(cp)) : [];
        this.systemIntegrators = input.system_integrators ? input.system_integrators.map(si => new SystemIntegrator().deserialize(si)) : [];
        return this;
    }

    serialize() {
        return {
            guid: this.guid,
            name: this.name,
            active: this.active,
            logo: this.logo,
            address: this.address.serialize(),
            articles: this.articles ? this.articles.map(a => a.serialize()) : '',
            contact_persons: this.contactPersons.map(cp => cp.serialize()),
            system_integrators: this.systemIntegrators.map(si => si.serialize())
        }
    }
}

export class Article implements Serializable<Article> {
    guid: string;
    name: string;
    selected: boolean;
    faceValue: number;
    number: number;
    currency: Currency;
    size: string;
    edi: string;
    reorder: boolean;
    articleCategory: Category;
    articleType: Type;
    articlePhotos: ArticlePhoto[];
    articleCountingMode: CardCountingMode;
    brand: Brand;

    deserialize(input) {
        this.guid = input.guid;
        this.name = input.name;
        this.selected = input.active;
        this.faceValue = input.face_value;
        this.number = input.number;
        this.currency = input.article_currency;
        this.size = input.size;
        this.edi = input.edi;
        this.reorder = input.is_order === undefined ? false : input.is_order;
        this.articleCategory = new Category().deserialize(input.article_category || {});
        this.articleType = new Type().deserialize(input.article_type || {});
        this.articlePhotos = input.article_photos ? input.article_photos.map(ap => new ArticlePhoto().deserialize(ap)) : [];
        this.articleCountingMode = input.article_counting_mode;
        this.brand = input.brand ? new Brand().deserialize(input.brand) : new Brand();
        return this;
    }

    serialize() {
        return {
            guid: this.guid,
            name: this.name,
            active: this.selected,
            face_value: this.faceValue,
            number: this.number,
            article_currency: this.currency,
            size: this.size,
            edi: this.edi,
            is_order: this.reorder,
            article_category: this.articleCategory.serialize(),
            article_type: this.articleType.serialize(),
            article_photos: this.articlePhotos ? this.articlePhotos.map(ap => ap.serialize()) : '',
            article_counting_mode: this.articleCountingMode,
            brand_id: this.brand.guid,
            brand_logo: this.brand.logo
        }
    }

    getActivePhoto(): ArticlePhoto {
        return this.articlePhotos.filter((photo: ArticlePhoto) => photo.selected)[0];
    }
}

export class Brand implements Serializable<Brand> {
    guid: string;
    name: string;
    logo: string;

    constructor() {
        this.guid = '';
        this.name = '-';
        this.logo = '';
    }

    deserialize(input) {
        this.name = input.name ? input.name : '-';
        this.logo = input.logo ? input.logo : '';
        this.guid = input.guid ? input.guid : '';
        return this
    }

    serialize() {
        return {
            guid: this.guid,
            name: this.name,
            logo: this.logo
        }
    }
}

export class ArticlePhoto implements Serializable<ArticlePhoto> {
    guid: string;
    photo: string;
    active: boolean;
    selected: boolean;
    name: string;

    deserialize(input) {
        this.guid = input.guid;
        this.photo = input.photo;
        this.active = input.active;
        this.selected = input.selected;
        this.name = input.name;
        return this;
    }

    serialize() {
        return {
            guid: this.guid,
            photo: this.photo,
            active: this.active,
            selected: this.selected,
            name: this.name
        }
    }
}

export class Currency implements Serializable<Currency> {
    id: number;
    slug: string;
    code: string;
    name: string;
    popularity: number;

    deserialize(input) {
        this.id = input.id;
        this.slug = input.slug;
        this.code = input.code;
        this.name = input.name;
        this.popularity = input.popularity;
        return this;
    }

    serialize() {
        return {
            id: this.id,
            slug: this.slug,
            code: this.code,
            name: this.name,
            popularity: this.popularity
        }
    }
}

export class ArticleBrand implements Serializable<ArticleBrand> {
    id: string;
    name: string;
    guid: string;
    created: Date;

    deserialize(input) {
        this.id = input.id;
        this.name = input.name;
        this.guid = input.guid;
        this.created = new Date(input.created.date);
        return this;
    }

    serialize() {
        return {
            id: this.id,
            name: this.name,
            guid: this.guid,
            created: this.created
        }
    }
}

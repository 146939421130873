import { PipeTransform, Pipe } from "@angular/core";

/**
 * Pipe that takes a collection of items in a group of selects and filters the already selected ones
 * Items in collection must be objects and have a unique guid as one of it's keys
 * @param alreadySelcted - string array of selected guids
 */
@Pipe({ name: 'filterAlreadySelected' })
export class AlreadySelectedPipe implements PipeTransform {
    transform(collection: any[], alreadySelected: string[]) : any[] {
        collection = collection || [];
        return collection.filter(item => {
          if (item.guid) {
            return !alreadySelected.includes(item.guid)
          } else if (item.identifier) {
            return !alreadySelected.includes(item.identifier)
          }
        });
    }
}

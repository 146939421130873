import { Serializable } from './../new-model/serializable';
import { SurveyQuestionJump } from './surveyQuestionJump';
import { TagStub } from '../new-model/tag';

export class SurveyQuestionChoice implements Serializable<SurveyQuestionChoice> {
    guid: string;
    choice: string;
    choiceReport?: string;
    questionJump?: SurveyQuestionJump;
    tag?: TagStub[];

    deserialize(element) {
        this.guid = element.guid || '';
        this.choice = element.choice || '';
        this.choiceReport = element.choice_report || element.choice;
        this.tag = element.tag || [];

        if (element.survey_question_answer_jump) {
            const hasQuestion = element.survey_question_answer_jump.survey_question && element.survey_question_answer_jump.survey_question.guid;

            if (hasQuestion) {
                const questionGuid = element.survey_question_answer_jump.survey_question.guid;
                this.questionJump = new SurveyQuestionJump(element.guid, element.survey_question_answer_jump.is_end_jump, questionGuid, element.survey_question_answer_jump.guid);
            }
            else {
                this.questionJump = new SurveyQuestionJump(element.guid, element.survey_question_answer_jump.is_end_jump, null, element.survey_question_answer_jump.guid);
            }

        }

        return this;
    }

    serialize() {
        const toReturn: any = {};

        toReturn.choice = this.choice;
        toReturn.choice_report = this.choiceReport;

        if (this.tag && this.tag.length) {
            toReturn.tag = this.tag.map(t => t.guid);
        }

        if (this.guid && this.guid.length > 0) {
            toReturn.guid = this.guid;
        }

        if (this.questionJump && this.questionJump.questionGuid) {
            toReturn.question_jump = this.questionJump.questionGuid;
        }

        if (this.questionJump && this.questionJump.end) {
            toReturn.end_jump = true;
        }

        return toReturn;
    }
}
import { Component, OnInit } from '@angular/core';
import { LogsService } from '../../../../services/logs.service';
import { StoreLog, PackageLog, PackageLogsDataSource, PackageLogTableItem } from '../../../../models/new-model/log';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {
  public searchTerm = '';
  public storeLog: StoreLog;
  public dataSource: PackageLogsDataSource = new PackageLogsDataSource([]);
  public foundData = true;

  constructor(
    private logsService: LogsService
  ) { }

  ngOnInit() {
    this.getStoreLogs();
  }

  private updateDataSource(storeLog: StoreLog) {
    this.storeLog = storeLog;
    this.foundData = storeLog.packages.length ? true : false;
    this.dataSource = new PackageLogsDataSource(
      storeLog.packages.map(
        (packageLog: PackageLog) => new PackageLogTableItem(packageLog)
      )
    );
  }

  searchTermChanged(term: string) {
    this.searchTerm = term;
    this.getStoreLogs();
  }

  getStoreLogs() {
    if (this.searchTerm.length) {
      this.logsService.getStoreLogsByName(this.searchTerm)
        .subscribe(storeLogs => this.updateDataSource(storeLogs));
    }
  }

}



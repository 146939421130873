import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ExportVisitsService } from '../../services/exportVisits.service';
import { Project, ProjectDate } from '../../models/new-model/project';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { ContentProvider } from '../../models/new-model/content-provider';
import { ContentProviderService } from '../../services/content-provider.service';
import { ExportProjectVisit } from "../../models/new-model/exportProjectVisit";

import { VisitStatus } from '../../models/new-model/store-visit';
import { FixturesService } from '../../services/fixtures.service';
import { Fixture } from '../../models/new-model/fixture';
import * as  moment from 'moment/moment';


@Component({
  selector: 'export-project-visits',
  templateUrl: './export-project-visits.component.html',
  styleUrls: ['./export-project-visits.component.scss'],
})
export class ExportProjectVisitsComponent implements OnInit {


  storeVisit: VisitStatus;
  visitStatuses;
  fixtures;
  startProject;
  endProject;
  project: Project;
  isReorder = false;

  disabled = true;
  successDownload: boolean;
  failDownloadMessage: string;
  exportForm: FormGroup;
  contentProviderList: ExportContentProvider[];
  allFixture: Fixture;
  exportProjectVisit: ExportProjectVisit;
  isStartValid = false;
  isEndValid = false;
  isCheckedValid = false;

  public loading = false;
  public dataLoaded = true;

  constructor(public dialogRef: MatDialogRef<ExportProjectVisitsComponent>, public exportService: ExportVisitsService, public fixtureService: FixturesService, public contentProviderService: ContentProviderService, private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { project: Project, editingMode: boolean }, ) {

    this.allFixture = new Fixture().deserialize({ guid: 'all', name: 'All Fixtures' });
    this.visitStatuses = VisitStatus.getAllStatuses();
    this.fixtures = [this.allFixture];
    this.project = data.project;
    this.startProject = data.project.start.date;
    this.endProject = data.project.end.date;
    this.exportForm = this.createForm();
  }

  ngOnInit() {
    this.loading = true;

    this.contentProviderService.getProjectContentProviders(this.project.guid)
      .subscribe(
        providers => {
          this.contentProviderList = providers.map(p => ({ contentProvider: p, selected: false }));

          const providerControl = this.contentProviderList.map(p => new FormControl());

          this.exportForm = this.fb.group({
            'start': this.fb.control(''),
            'end': this.fb.control(''),
            'status': new FormControl(this.visitStatuses[0]),
            'fixture': new FormControl(this.fixtures[0]),
            'providerList': new FormArray(providerControl)
          });
          this.loading = false;
          this.dataLoaded = true;
        },
        () => {
          this.loading = false;
          this.dataLoaded = true;
        })
  }

  getFormArray(name): FormArray {
    return this.exportForm.get(name) as FormArray;
  }

  createForm() {
    return this.exportForm = this.fb.group({
      status: ['', Validators.required],
      fixture: [''],
      start: ['', Validators.required],
      end: ['', Validators.required],
      providerList: [this.fb.array([new ExportContentProvider()]), Validators.required]
    });
  }

  cancel() {
    this.dialogRef.close();
  }
  unsetDisable(e) {
    //enable export button
    if (this.exportForm.valid) {
      this.disabled = false;
    }
  }

  export(e) {
    this.exportProjectVisit = new ExportProjectVisit().deserialize(this.createExportData());
    console.log(this.exportProjectVisit);
    //export csv file
    this.exportService.downloadFile(this.exportProjectVisit, this.project.name).subscribe(res => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    }, error => {
      this.successDownload = false;
      this.failDownloadMessage = JSON.stringify(error);
      console.log('download error:', JSON.stringify(error));
    }, () => {
      this.successDownload = true;
      this.dialogRef.close();
    });
  }


  createExportData() {
    return {
      projectGuid: this.project.guid,
      contentProvidersGuid: this.contentProviderList.filter(p => p.selected == true).map(p => p.contentProvider.guid),
      startTime:this.exportForm.get('start').value.format('YYYY-MM-DD'),
      endTime:this.exportForm.get('end').value.format('YYYY-MM-DD'),
      state: this.exportForm.get('status').value.visit,
      fixture: this.exportForm.get('fixture').value.guid == undefined ? 'all' : this.exportForm.get('fixture').value.guid,
      reorder: this.isReorder
    }
  }
  onStartDateChange(startDate) {
    this.exportForm.patchValue({ start: startDate });

    this.isStartValid = true;
    if (this.exportForm.valid && this.isCheckedValid && this.isStartValid && this.isEndValid) {
      this.disabled = false;
    }
  }

  onEndDateChange(endDate) {
    this.exportForm.patchValue({ end: endDate });
    this.isEndValid = true;
    if (this.exportForm.valid && this.isCheckedValid && this.isStartValid && this.isEndValid) {
      this.disabled = false;
    }
  }

  onCheckChange(provider: ExportContentProvider) {
    provider.selected = !provider.selected;
    let contentProvidersGuid = this.contentProviderList.filter(p => p.selected == true).map(p => p.contentProvider.guid);
    this.fixtures = [this.allFixture];

    if (contentProvidersGuid.length > 0) {

      this.isCheckedValid = true;
      this.fixtureService.getContentProviderFixtureList(this.project.guid, contentProvidersGuid).subscribe(
        result => {
          if (result.length > 0) {
            this.fixtures = this.fixtures.concat(result);
          }
          this.exportForm.patchValue({ fixture: this.fixtures[0] });
          if (this.exportForm.valid && this.isCheckedValid && this.isStartValid && this.isEndValid) {
            this.disabled = false;
          }
        });
    }
    else {
      this.isCheckedValid = false;
      this.fixtures = [];
      this.disabled = true;
    }
  }
}


export class ExportContentProvider {
  contentProvider: ContentProvider;
  selected: boolean;
}

import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "./../services/translate.service";
@Pipe({
  name: "translate",
  pure: false
})
export class TranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(key: string): string {
      return this.translate.data[key] || key;
  }

  // if things get nasty

  // transform(keyPath: any): any {
  //   return this.getNestedObject(this.translate.data, keyPath) || null;
  // }
  // getNestedObject(nestedObj, pathArr) {
  //   return pathArr.reduce(
  //     (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
  //     nestedObj
  //   );
  // }
}

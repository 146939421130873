import { CustomTableDataSource } from "../../plt-shared/plt-custom-table/custom-table-data-source";
import { SelectableTableEntry, ActiveTableEntry } from "../../plt-shared/plt-custom-table/plt-custom-table/plt-custom-table.component";
import * as moment from 'moment';

export class MerchandiserLog {
  lastLogin: Date;
  lastLogout: Date;
  packages: PackageLog[];

  constructor(input) {
    this.lastLogin = input.user.last_login ? new Date(input.user.last_login) : null;
    this.lastLogout = input.user.last_login ? new Date(input.user.last_logout) : null;
    this.packages = input.packages
      .reduce((acc, packageLog) =>
        acc.concat(
          packageLog.import.length ?
            packageLog.import.map(i => new PackageLog({
              visitId: packageLog.visit.id,
              storeId: packageLog.store.store_num,
              downloadDateAndTime: packageLog.export.date,
              uploadDateAndTime: i.date
            })) :
            new PackageLog({
              visitId: packageLog.visit.id,
              storeId: packageLog.store.store_num,
              downloadDateAndTime: packageLog.export.date,
              uploadDateAndTime: ' - '
            }))
        , []);
  }
}

export class StoreLog {
  packages: PackageLog[];

  constructor(input) {
    this.packages = input.packages
      .reduce((acc, packageLog) =>
        acc.concat(
          packageLog.import.length ?
            packageLog.import.map(i => new PackageLog({
              visitId: packageLog.visit.id,
              merchandiserName: packageLog.user,
              downloadDateAndTime: packageLog.export.date,
              uploadDateAndTime: i.date
            })) :
            new PackageLog({
              visitId: packageLog.visit.id,
              merchandiserName: packageLog.user,
              downloadDateAndTime: packageLog.export.date,
              uploadDateAndTime: ' - '
            }))
        , []);
  }
}

export class PackageLog {
  visitId: string;
  merchandiserName?: string;
  storeId?: string;
  downloadDateTime: Date;
  uploadDateTime: Date;

  constructor(input) {
    this.visitId = input.visitId;
    this.storeId = input.storeId ? input.storeId : '';
    this.merchandiserName = input.merchandiserName ? input.merchandiserName : '';
    this.downloadDateTime = new Date(input.downloadDateAndTime);
    this.uploadDateTime = new Date(input.uploadDateAndTime);
  }
}

export class PackageLogsDataSource extends CustomTableDataSource<PackageLogTableItem> { }

export class PackageLogTableItem extends SelectableTableEntry implements ActiveTableEntry {
  visitId: string;
  merchandiserName?: string;
  storeId?: string;
  downloadDateTime: string;
  uploadDateTime: string;

  constructor(packageLog: PackageLog) {
    super();
    this.visitId = packageLog.visitId;
    this.storeId = packageLog.storeId ? packageLog.storeId : '';
    this.merchandiserName = packageLog.merchandiserName ? packageLog.merchandiserName : '';
    this.downloadDateTime = isNaN(packageLog.downloadDateTime.getTime()) ? ' - ' : moment(packageLog.downloadDateTime).format('DD.MM.YYYY - HH:mm');
    this.uploadDateTime = isNaN(packageLog.uploadDateTime.getTime()) ? ' - ' : moment(packageLog.uploadDateTime).format('DD.MM.YYYY - HH:mm');
  }
}
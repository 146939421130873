import { StoreVisit } from "./store-visit";
import { Retailer } from "./retailer";
import { Store } from "../visit-planning/store";
import { ContentProvider, Article } from "./content-provider";
import { Fixture, FixtureType } from "./fixture";
import { Serializable } from '../new-model/serializable';

export class CheckerData {
    visit: StoreVisit;
    retailer: Retailer;
    store: Store;
    distance: Distance;

    constructor(element: any) {
        this.visit = new StoreVisit(element.visit || {});
        this.retailer = new Retailer().deserialize(element.retailer || {});
        this.store = new Store().deserialize(element.store || {});
        this.distance = new Distance(element.distance || {});
    }
}


export class Distance {
    value: string;
    units: string;

    constructor(element: any) {
        this.value = element.value || '';
        this.units = element.units || '';
    }

    toString(): string {
        return this.value + this.units;
    }
}

export class CheckerPhoto implements Serializable<CheckerPhoto> {
    photoGuid: string;
    photo: string;
    question: string;

  

    deserialize(element) {
        if (element.guid) {
            this.photoGuid = element.guid || '';
            this.photo = element.photo || '';
            this.question = element.question || '';
            return this;
        } else return null
        
    }

    serialize() {
        return {
            guid: this.photoGuid,
            photo: this.photo,
            question: this.question,
        };
    }

}

export class CheckerCount {
    contentProvider: ContentProvider;
    article: Article;
    entry: number;
    exit: number;
    min: number;

    constructor(element) {
        this.contentProvider = new ContentProvider().deserialize(element.content_provider);
        this.article = new Article().deserialize(element.article);
        this.entry = element.entry;
        this.exit = element.exit;
        this.min = element.min;
    }
}

export class FixtureInstance {
    storesId: number;
    guid: string;
    counter: number;
    fixture: Fixture;
    fixtureType: FixtureType;

    constructor(input: any) {
        this.storesId = input.stores_id;
        this.guid = input.guid;
        this.counter = input.counter;
        this.fixture = new Fixture().deserialize(input.fixture || {});
        this.fixtureType = new FixtureType().deserialize(input.fixture_type);
    }

}

import { Project } from './project';
import { ContentProvider } from './content-provider';
import { Serializable } from "../new-model/serializable";


export class ExportProjectVisit implements Serializable<ExportProjectVisit> {

  projectGuid: string;
  contentProvidersGuid: string[];
  startTime: string;
  endTime: string;
  state: string;
  fixture: string;
  reorder: boolean;

  deserialize(input) {
    this.projectGuid = input.projectGuid;
    this.contentProvidersGuid = input.contentProvidersGuid;
    this.startTime = input.startTime;
    this.endTime = input.endTime;
    this.state = input.state;
    this.fixture = input.fixture;
    this.reorder = input.reorder;

    return this
  }

  serialize() {
    return {
      projectGuid: this.projectGuid,
      contentProvidersGuid: this.contentProvidersGuid,
      startTime: this.startTime,
      endTime: this.endTime,
      state: this.state,
      fixture: this.fixture,
      reorder: this.reorder
    }
  }
}
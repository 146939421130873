import { Serializable } from '../new-model/serializable';

export class QualitySurveyQuestion
  implements Serializable<QualitySurveyQuestion> {

  guid: string;

  parent_guid: string;

  question: string;

  failurePoints: number;

  sort: number;

  answer?: QualitySurveyAnswer;

  type?: string;

  choices?: QualitySurveyAnswerChoice[];

  deserialize(input: any): QualitySurveyQuestion {
    return <QualitySurveyQuestion>{
      guid: input.guid,
      parent_guid: input.parent_guid,
      question: input.question,
      failurePoints: input.failure_points,
      sort: input.sort,
      answer: new QualitySurveyAnswer(input.answer),
      type: input.type ? input.type : 'standard',
      choices: input.choices && input.choices.length ?
        input.choices.map(choice => new QualitySurveyAnswerChoice().deserialize(choice)) : null
    };
  }

  serialize() {
    return {
      guid: this.guid,
      parent_guid: this.parent_guid,
      question: this.question,
      failure_points: this.failurePoints,
      sort: this.sort,
      type: this.type,
      choices: this.choices && this.choices.length ?
        this.choices.map(choice => choice.serialize()) : null
    };
  }
}

export class QualitySurveyAnswer {
  guid: string;
  answer: string;

  constructor(input?) {
    this.guid = input ? input.guid : '';
    if (input && input.answer !== null) {
      this.answer = input.answer.toString();
    } else if (input && input.single_choice) {
      this.answer = input.single_choice;
    } else if (input && input.float) {
      this.answer = input.float;
    } else {
      this.answer = '';
    }
  }
}

export class QualitySurveyAnswerForm extends QualitySurveyAnswer {
  questionId: string;

  constructor(input?) {
    super(input ? input.answer : null);
    this.questionId = input && input.questionId ? input.questionId : '';
  }
}

export class QualitySurveyAnswerChoice implements Serializable<QualitySurveyAnswerChoice>{
  guid: string;
  choice?: string;
  min?: number;
  max?: number;
  failurePoints: number;

  deserialize(input): QualitySurveyAnswerChoice {
    this.guid = input.guid;
    if (input.choice && !!input.choice.length) {
      this.choice = input.choice;
    } else if (!isNaN(input.min) && !isNaN(input.max)) {
      this.min = input.min;
      this.max = input.max;
    }
    this.failurePoints = input.failure_points;
    return this;
  }

  serialize() {
    const res = {
      guid: this.guid,
      failure_points: this.failurePoints
    };
    if (this.choice && !!this.choice.length) {
      res['choice'] = this.choice;
    } else if (this.min && this.max) {
      res['min'] = this.min;
      res['max'] = this.max;
    }
    return res;
  }

}
import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { UserService } from "./../../../../services/user.service";

@Component({
  selector: "delete-user-modal",
  templateUrl: "./delete-user-modal.component.html",
  styleUrls: ["./delete-user-modal.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DeleteUserModalComponent implements OnInit {
  username: string;
  userGuid: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService
  ) {
    this.username = data.username;

    this.userGuid = data.guid;
  }

  ngOnInit() {}

  delete() {
    this.userService.deleteUser(this.userGuid).subscribe(() => this.dialogRef.close())
  }

  cancel() {
    this.dialogRef.close();
  }
}

import { ProjectAccountDetails } from './project';
import { Serializable } from './serializable';
import { CommunicationDetail, ContactPerson } from './contact-person';
export class User implements Serializable<User> {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  roles?: Role[];
  communicationDetails: CommunicationDetail[];


  deserialize(input) {
      this.salutation = input.salutation;
      this.firstName = input.first_name;
      this.lastName = input.last_name;
      this.email = input.email;
      this.communicationDetails = input.communication_details ? input.communication_details.map(cd => new CommunicationDetail().deserialize(cd)) : [];
      this.roles = input.roles ? input.roles.map( r => new Role().deserialize(r)) : [];
      return this;
  }

  serialize() {
      return {
          salutation: this.salutation,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          communication_details: this.communicationDetails.map(cd => cd.serialize()),
          roles: this.roles.map( r => r.serialize().identifier)
      }
  }
}


export class Role {
  identifier: string;
  name: string;

  constructor() {
    this.identifier = '';
    this.name = '';
  }

  deserialize(input) {
    this.identifier = input.identifier || '';
    this.name = input.name || '';
    return this;
  }

  serialize() {
    return {
      identifier: this.identifier,
      name: this.name
    }
  }

  getRoleIdentifier() {
    return this.identifier
  }
}

export class UserDetails implements Serializable<UserDetails> {
  account: ProjectAccountDetails;
  contactPerson: ContactPerson;
  roles: Role[];



  deserialize(input) {
      this.account = new ProjectAccountDetails().deserialize( input.account || {});
      this.contactPerson = new ContactPerson().deserialize(input.contact_person || {});
      this.roles = input.roles ? input.roles.length ? input.roles.map( r => new Role().deserialize(r)) : [] : [];
      return this;
  }

  serialize() {
      return {
        account: this.account,
        contactPerson: this.contactPerson,
        roles: this.roles.map( r => new Role().deserialize(r))
      }
  }
}
